import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QrLookUp from "../../api/QrLookup";
import OpenPositionCard from "../../components/OpenPositionCard/OpenPositionCard";
import { Collapse, Space } from "antd";
import ImgAvatarArrow from "../../assets/images/profile-round.svg";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { defaultUserImage } from "../../assets/images";

const { Panel } = Collapse;

const PreviousQR = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [error, setError] = useState('');
  const [positions, setPositions] = useState([]);
  const [brand, setBrand] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    console.log('params', params);
    if(params?.id) {
      loadBrand();
    }
  }, []);

  const loadBrand = async () => {
    await QrLookUp.getByPreviousid(params?.id)
    .then((response) => {
      const tempObj = response?.data?.data;
      console.log('tempObj', tempObj);
      if (tempObj?.entity_type === "brand") {
        navigate(`/${tempObj?.brand?.url_slug}`);
      } else if (tempObj?.entity_type === "job-position") {
        setPositions([tempObj?.job_position]);
        setBrand(tempObj?.job_position?.location?.brand);
        setLocation(tempObj?.job_position?.location);
      } else {
        navigate(`/${tempObj?.location?.brand?.url_slug}/${tempObj?.location?.url_slug}`);
      }
    }).catch((error) => {
        console.log('error', error);
      setError(error?.response?.data?.message);
    });
  }

  return (
    <div className="page-public-profile h-full bg-gray-300">
    <div className="public-profile flex flex-col h-full">
      <div className="h-32 md:h-32 bg-red-400 md:rounded-b-3xl header">
        <div className="max-w-[1280px] w-full h-full mx-auto p-4">
          <div className="h-full flex justify-end items-center md:items-end">
            <button className="hidden md:block px-4 py-2 rounded-md border border-primary-500 bg-white hover:bg-primary-500 text-primary-500 hover:text-white cursor-pointer">
              Share <i className="fas fa-share-all ml-1" />
            </button>
            <button className="md:hidden px-4 py-2 rounded-md border border-warmgray-900 bg-white hover:bg-warmgray-900 text-warmgray-900 hover:text-white cursor-pointer">
              <i className="fas fa-share-all" />
            </button>
          </div>
        </div>
      </div>
      <div className="max-w-[1280px] w-full mx-auto flex-1">
        <div className="flex h-full flex-col md:flex-row">
          <div className="w-full md:w-[383px] bg-primary-400 text-white flex flex-col">
            <div className="relative hidden top-[-70px] md:flex flex-col px-6 h-100">
              <div className="relative flex justify-center bg-white rounded-full p-3 w-[145px] h-[145px] mx-auto">
                <img
                  className={"rounded-full"}
                  height="125"
                  width="125"
                  src={brand?.photo?.thumb_url || defaultUserImage}
                  alt={location?.name}
                />
                <img
                  src={ImgAvatarArrow}
                  alt="avatar"
                  className="absolute right-1 bottom-0"
                />
              </div>

              <span className="block font-bold text-white text-xl mt-7 mb-4">
                {location?.name}
              </span>

              {location?.address?.address && (<div className="flex items-center mb-4">
                <div className="text-center min-w-[28px]">
                  <i className="far fa-map-marker-alt col-span-1 text-xl text-white-400 text-center self-center" />
                </div>
                <span className="ml-1 col-span-7">{location?.address?.address}</span>
              </div>)}

              <div className="flex items-center mb-6">
                <div className="text-center min-w-[28px]">
                  <i className="far fa-home col-span-1 text-xl text-white-400 text-center self-center" />
                </div>
                <span className="ml-1 col-span-7 self-center underline">
                  {location?.website || brand?.website}
                </span>
              </div>

              <span className="block font-bold text-white text-xl mt-7 mb-4">
                About {location?.name}:
              </span>

              <span className="block text-white-900">
                {location?.description || brand?.description}
              </span>
            </div>

            <div className={"hidden md:block mt-auto"}>
              <div className="flex justify-center mb-4">
                <img
                  width={100}
                  src="/images/play_store_badge.png"
                  alt="Play Store"
                />
                <img
                  width={100}
                  className="ml-1"
                  src="/images/app_store_badge.png"
                  alt="App Store"
                />
              </div>
              <div className="shadow-2xl text-center bg-white text-primary-500 py-4 flex justify-center">
                <span className="font-semibold self-center mr-2">Powered by </span>
                <img
                  height={10}
                  width={120}
                  alt="logo"
                  src="/logo/powered-by.svg"
                />
              </div>
            </div>

            <Collapse
              className="public-profile-collapse md:hidden"
              ghost
              expandIconPosition="right"
              expandIcon={({ isActive }) => isActive ? <FiChevronUp size={24} color="white" /> : <FiChevronDown size={24} color="white" />}
            >
              <Panel
                header={
                  <div className={"flex text-white"}>
                    <div className={"rounded-full border-4 border-white h-min"}>
                      <img
                        className={"rounded"}
                        src={brand?.photo?.thumb_url || defaultUserImage}
                        alt={location?.name}
                        width={40}
                        height={40}
                      />
                    </div>
                    <div className={"ml-2"}>
                      <span className={"block text-lg font-bold"}>
                        {location?.name}
                      </span>
                      <span className={"block underline cursor-pointer"}>
                        {location?.website || brand?.website}
                      </span>
                    </div>
                  </div>
                }
                key="1"
              >
                <div>
                  <p className={"text-white ml-14"}>{location?.address?.address}</p>
                  <p className={"mt-4 text-white"}>
                    {location?.description || brand?.description}
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="flex-1 md:ml-0 lg:ml-16 md:pt-8 p-4 pb-44 md:pb-6">
            {!error ? 
              (
                <div className="h-full">
                  <div className="flex bg-white shadow-lg  p-2 px-4 md:p-6 rounded-md">
                    <p className="font-semibold">({positions?.length}) Open Position(s)</p>
                  </div>

                  <div className="my-3 md:my-4">
                    {positions?.map((item, i) => (
                      <Space key={item.id} direction="vertical" size={8} className="w-full">
                        <OpenPositionCard position={item} />
                      </Space>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex w-full h-full justify-center items-center">
                  <span className="text-slate-500 text-xl md:text-6xl font-black tracking-widest decoration-slate-500 antialiased">{error}</span>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>

    <div className={"fixed md:hidden w-full bottom-0"}>
      <div
        className={
          "px-4 py-5 bg-primary-500 flex items-center shadow-[3px_0_0_2px_rgba(0,0,0,0.1)]"
        }
      >
        <span className={"text-white font-thin"}>
          Want to <strong className="font-bold">apply</strong> for this <strong className="font-bold">job?</strong>
        </span>
        <img
          width={150}
          className="ml-4"
          src="/images/app_store_badge.png"
          alt="App Store"
        />
      </div>
      <div className="text-center bg-white text-primary-500 text-white py-4 flex justify-center">
        <span className="font-semibold self-center mr-2">Powered by </span>
        <img height={10} width={120} alt="logo" src="/logo/powered-by.svg"/>
      </div>
    </div>
  </div>
  );
};

export default PreviousQR;
