import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Brand from "./api/Brand";
import RoutesList from "./routes";
import './App.css';


function App() {

  const [path, setPath] = useState('');
  const [brand, setBrand] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(getStoredLocation());

  // useEffect(() => {
  //   //loadBrand();
  // }, []);

  // const loadBrand = async () => {
  //   try {
  //     await Brand.getBrand('gilats-cafe-bakery-brand')
  //     .then((response) => {
  //       const tempObj = response.data.data;
  //       setBrand(tempObj);
  //       if (tempObj?.location_count > 1) {
  //          setPath(`/${tempObj.url_slug}`);
  //       } else {
  //          setPath(`/${tempObj.url_slug}/${tempObj.locations[0].url_slug}`);
  //       }
  //     });
  //   } catch (error) {
  //     setPath('/');
  //   }
  // }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBrCuoDfHzbd7Ejfs3cX9b1x9ANUAGPiWg&libraries=places";
    script.async = true;
    document.head.appendChild(script);

    script.addEventListener('load', () => {
      
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            
            getAddressFromCoordinates(latitude, longitude);
          },
          (error) => {
            console.error(`Error getting location: ${error.message}`);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    });

    script.addEventListener('error', () => {
      console.error('Error loading Google Maps API script');
    });

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  function getAddressFromCoordinates(latitude, longitude) {
    const latLng = new window.google.maps.LatLng(latitude, longitude);
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ 'location': latLng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          let city, state;

          addressComponents.forEach(component => {
            if (component.types.includes('locality')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.short_name;
            }
          });

          if (city && state) {
            const newLocation = { city, state, latitude, longitude };
            setCurrentLocation(newLocation);
            storeLocation(newLocation);
          } else {
            console.error('City or state not found in address components.');
          }
        } else {
          console.error('No address found for the given coordinates.');
        }
      } else {
        console.error(`Geocoder failed due to: ${status}`);
      }
    });
  }

  function getStoredLocation() {
    const storedLocation = localStorage.getItem('currentLocation');
    return storedLocation ? JSON.parse(storedLocation) : null;
  }

  function storeLocation(location) {
    localStorage.setItem('currentLocation', JSON.stringify(location));
  }
  
  
  return (
    <BrowserRouter>
      <Routes>
        { path !== '' ?
        <Route path="/" element={<Navigate to={path} state={{ brand: brand }} />} /> : null}
        {RoutesList.map((ro, i) => (
          <Route
            exact
            key={i}
            path={ro.path}
            element={ro.element}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
