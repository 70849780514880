import LocationProfile from "./pages/LocationProfile";
import QrLookUpPage from "./pages/QrLookUp";
import BrandEmbeddedProfile from "./pages/BrandEmbeddedProfile";
import LocationEmbeddedProfile from "./pages/LocationEmbeddedProfile";
import PreviousQR from "./pages/PreviousQR";
import NewBrandProfile from "./pages/BrandProfile/NewBrandProfile";
import BrandProfile from "./pages/BrandProfile";
import Brands from "./pages/Brands/Brands";
import BusinessProfile from "./pages/BusinessProfile/BusinessProfile";
import PositionProfile from "./pages/PositionProfile/PositionProfile";
import NewLocationProfile from "./pages/NewLocationProfile/NewLocationProfile";
import GroupProfile from "./pages/GroupProfile/GroupProfile";
import MainPage from "./pages/Brands/MainPage";

const RoutesList = [
  // public profiles
  { name: "Brand", path: "/", element: <MainPage/>},
  { name: "Brands", path: "/jobs", element: <MainPage/>},
  { name: "Businesses", path: "/businesses", element: <MainPage/>},
  { name: "Brand Profile", path: "/:businessName", element: <BusinessProfile />},
  { name: "Group Profile", path: "/group/:groupUrl", element: <GroupProfile />},
  { name: "Group Profile", path: "/group/:groupUrl/jobs", element: <GroupProfile />},
  { name: "Group Profile", path: "/group/:groupUrl/businesses", element: <GroupProfile />},
  { name: "Location Profile", path: "/:businessName/:locationName", element: <NewLocationProfile />},
  { name: "Position Profile", path: "/:businessName/:locationName/:positionName", element: <PositionProfile />},
  { name: "QR LookUp", path: "/qr/:uid", element: <QrLookUpPage /> },
  {
    name: "Previous QR LookUp",
    path: "/previous-qr/:id",
    element: <PreviousQR />,
  },
  // { name: "Brand Profile", path: "/:brandSlug", element: <BrandProfile /> },
  {
    name: "Brand Profile",
    path: "austin/:brandSlug",
    element: <NewBrandProfile />,
  },
  {
    name: "Brand Embedded Profile",
    path: "/embed/:brandSlug",
    element: <BrandEmbeddedProfile />,
  },
  // {
  //   name: "Location Profile",
  //   path: "/:brandSlug/:locationSlug",
  //   element: <LocationProfile />,
  // },
  {
    name: "Location Embedded Profile",
    path: "/embed/:brandSlug/:locationSlug",
    element: <LocationEmbeddedProfile />,
  },
];

export default RoutesList;
