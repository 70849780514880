import React, { useEffect, useState } from "react";
import {
  SelectedIcon,
  defaultBrandImage,
  heyhireNewLogoIcon,
  searchNewLogo,
} from "../../assets/images";
import "./Brands.scss";
import Brand from "../../api/Brand";
import { useLocation, useNavigate } from "react-router-dom";
import CardPositionLoading from "../../utils/CardPositionLoading";
import CardBusinessLoading from "../../utils/CardBusinessLoading";

const MainPage = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState(null);
  const [positions, setPositions] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [storedLocation, setStoredLocation] = useState(
    JSON.parse(localStorage.getItem("currentLocation"))
  );
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPositionsData, setFilteredPositionsData] = useState([]);
  const [filteredBrandsData, setFilteredBrandsData] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const location = useLocation();
  const [searchTerms, setSearchTerms] = useState([]);
  const [searchTermsSet, setSearchTermsSet] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [initialFunc, setInitialFunc] = useState(false);

  useEffect(() => {
    if (storedLocation && storedLocation.city) {
      // setSearchTerms(["austin"]);
      setSearchTerms([storedLocation.city.toLowerCase()]);
      setSearchTermsSet(true);
    }
  }, [storedLocation, searchTermsSet]);

  useEffect(() => {
    if (storedLocation && storedLocation?.city && storedLocation?.state) {
      document.title = `HeyHire - Find Local Jobs You Love in ${storedLocation?.city}, ${storedLocation?.state}: Hospitality, Cafe, Bartending & More`;
    } else {
      document.title = `HeyHire - Find Local Jobs You Love : Hospitality, Cafe, Bartending & More`;
    }

    if (location.pathname.includes("/businesses")) {
      setSelectedFilter("Business Name");
    } else {
      setSelectedFilter("Positions");
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      setSearchTerm("");
      setLoading(true);
      try {
        if (selectedFilter === "Positions") {
          const response = await Brand.getAllPositions();
          setPositions(response?.data);
        } else {
          const response = await Brand.getAllBusinesses();
          setBrands(response?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (!initialFunc) {
      setInitialFunc(true);
    } else {
      fetchData();
    }
  }, [selectedFilter, initialFunc]);

  useEffect(() => {
    const boolean = selectedFilter === "Positions" ? positions : brands;
    setFilterLoading(true);
    if (boolean) {
      if (searchTerms.length === 0) {
        selectedFilter === "Positions"
          ? setFilteredPositionsData(positions)
          : setFilteredBrandsData(brands);
      } else {
        const newSearchResults = searchTerms.map((term) => {
          let updatedFilteredData =
            selectedFilter === "Positions" ? [...positions] : [...brands];

          updatedFilteredData = updatedFilteredData.filter((item) => {
            const itemJson = JSON.stringify(item);
            return itemJson.toLowerCase().includes(term.toLowerCase());
          });

          return updatedFilteredData;
        });
        const uniqueResults = Array.from(new Set(newSearchResults.flat()));
        selectedFilter === "Positions"
          ? setFilteredPositionsData(uniqueResults)
          : setFilteredBrandsData(uniqueResults);
      }
    }
    setFilterLoading(false);
  }, [searchTerms, positions, brands]);

  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase();

    if (event.key === "Enter") {
      setSearchTerms((prevSearchTerms) => [...prevSearchTerms, searchTerm]);
      setSearchTerm("");
    } else {
      setSearchTerm(searchText);
    }
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);

    if (filter === "Positions") {
      navigate("/jobs");
    } else if (filter === "Business Name") {
      navigate("/businesses");
    }

    setSearchTermsSet(false);
  };

  const handleRemoveSearchTerm = (termToRemove) => {
    setSearchTerms((prevSearchTerms) =>
      prevSearchTerms.filter((term) => term !== termToRemove)
    );
  };

  return (
    <div className="bg-white h-full overflow-hidden">
      <div className="h-16 md:h-20 top-0 w-full bg-gray-900 z-[1] rounded-b-2xl flex">
        <div className="m-auto w-full md:w-3/4 xl:w-3/4 px-2 md:px-4 lg:px-6 xl:px-0">
          <div className="xl:mx-36 flex justify-between gap-2">
            <div className="flex flex-col justify-start cursor-pointer">
              <img
                className="w-28 md:w-36"
                src={heyhireNewLogoIcon}
                alt="heyhire"
                onClick={() => {
                  navigate("/jobs");
                }}
              />
              <span className="font-bold text-[8px] w-28 md:w-36 flex justify-end text-white">
                {storedLocation?.city}, {storedLocation?.state}
              </span>
            </div>

            <div className="my-auto w-64 md:w-80 lg:w-96 search-display">
              <div className="relative">
                <input
                  type="search"
                  id="default-search"
                  onChange={handleSearch}
                  onKeyPress={handleSearch}
                  value={searchTerm}
                  className="block w-full p-2 pl-4 text-sm text-gray-900 border border-gray-400 rounded-full bg-gray-50 pr-12"
                  placeholder="Search Job title or name"
                  required
                />
                <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <img src={searchNewLogo} alt="" />
                </div>
              </div>
            </div>

            <div className="my-auto">
              <div
                className="cursor-pointer px-6 flex items-center justify-center bg-white text-black hover:bg-gray-900 hover:text-white hover:border-white border transition-all text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-2"
                onClick={() =>
                  window.open("https://www.heyhire.com/", "_blank")
                }
              >
                Post a Job
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto w-full md:w-3/4 xl:w-3/4 px-2 md:px-4 lg:px-6 xl:px-0">
        <div className="xl:mx-36 mt-4 lg:mt-8">
          <div className="m-auto w-64 search-display-mobile">
            <div className="relative">
              <input
                type="search"
                id="default-search"
                onChange={handleSearch}
                onKeyPress={handleSearch}
                value={searchTerm}
                className="block w-full p-2 pl-4 pr-12 text-sm text-gray-900 border border-gray-400 rounded-full bg-gray-50"
                placeholder="Search Job title or name"
                required
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <img src={searchNewLogo} alt="" />
              </div>
            </div>
          </div>

          <div className="flex flex-col my-4 mx-2 gap-2 sm:flex-row">
            <p className="text-[13px] font-semibold my-auto">Sort By:</p>
            <div className="flex flex-wrap whitespace-nowrap gap-1.5 text-sm">
              <div
                className={`cursor-pointer px-6 flex items-center justify-center bg-gray-900 text-white ${
                  selectedFilter === "Positions" ? "font-bold" : "font-normal"
                } text-center whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                onClick={() => handleFilterClick("Positions")}
              >
                {selectedFilter === "Positions" && (
                  <img src={SelectedIcon} alt="logo" className="mr-2 my-auto" />
                )}
                Positions
              </div>
              <div
                className={`cursor-pointer px-6 flex items-center justify-center bg-gray-900 text-white ${
                  selectedFilter === "Business Name"
                    ? "font-bold"
                    : "font-normal"
                } text-center whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                onClick={() => handleFilterClick("Business Name")}
              >
                {selectedFilter === "Business Name" && (
                  <img src={SelectedIcon} alt="logo" className="mr-2 my-auto" />
                )}
                Business Name
              </div>
            </div>
          </div>

          {searchTerms?.length > 0 && (
            <div className="flex flex-wrap mb-4 whitespace-nowrap gap-1 text-sm">
              {searchTerms?.map((term) => (
                <div
                  className="px-2 flex items-center justify-center bg-gray-900 text-white
                         text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-0.5"
                >
                  <i
                    className="cursor-pointer fas fa-times text-white text-[14px] mr-1.5 mt-0.5"
                    onClick={() => handleRemoveSearchTerm(term)}
                  />
                  {term}
                </div>
              ))}
            </div>
          )}

          {selectedFilter === "Business Name" && (
            <div className="h-[calc(100vh-13rem)] overflow-auto mt-4 bg-[#f6f6f6] rounded-xl">
              <div className="hidden md:flex flex-col">
                {filteredBrandsData &&
                  filteredBrandsData.length > 0 &&
                  filteredBrandsData.map((brand) => (
                    <div className="bg-white flex gap-2 my-2 mx-4 p-4 justify-between shadow-sm border border-warmgray-200 rounded-lg">
                      <div className="flex flex-col flex-grow">
                        <div className="flex flex-col gap-0.5 text-gray-900 mb-2">
                          <p className="font-extrabold tracking-wide">
                            {brand?.location?.name}
                          </p>
                          <p className="mt-1">
                            <span className="font-bold text-[14px] mr-1.5">
                              Address:
                            </span>
                            <span className="text-[12px]">
                              {brand?.location?.address?.address},{" "}
                              {brand?.location?.address?.city},{" "}
                              {brand?.location?.address?.state},{" "}
                              {brand?.location?.address?.zip}
                            </span>
                          </p>
                          <p className="text-[14px]">
                            Open Positions ({brand?.position_count})
                          </p>
                          {brand?.position_name?.length > 0 && (
                            <ul className="flex flex-wrap list-none gap-3 mx-4 pb-4">
                              {brand?.position_name.map((job, index) => (
                                <li
                                  key={index}
                                  className="text-[12px] list-disc mr-2 text-gray-600 ml-1"
                                >
                                  {job.title}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div className="mt-auto">
                          <div
                            className={`w-48 cursor-pointer px-6 border flex items-center justify-center bg-gray-900 text-white hover:bg-white hover:border-gray-900 hover:text-black transition-all
                            text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                            onClick={() => navigate(`/${brand?.brand_slug}`)}
                          >
                            View Positions
                          </div>
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <img
                          src={
                            brand?.photo?.photo
                              ? brand?.photo?.photo?.thumb_url
                              : defaultBrandImage
                          }
                          alt="brand_logo"
                          className="w-40 h-40 object-contain rounded-lg"
                        />
                      </div>
                    </div>
                  ))}

                {loading && (
                  <div className="justify-center w-full">
                    <div className="flex flex-col gap-4 mt-2 mx-4">
                      {Array.from({ length: 6 }).map((_, index) => (
                        <CardBusinessLoading key={index} />
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="bg-[#f6f6f6] flex md:hidden flex-col">
                {filteredBrandsData &&
                  filteredBrandsData.length > 0 &&
                  filteredBrandsData?.map((brand) => (
                    <div className="bg-white flex flex-col gap-2 my-2 mx-2 p-4 justify-between shadow-sm border border-warmgray-200 rounded-lg">
                      <div className="flex gap-4">
                        <div>
                          <img
                            src={
                              brand?.photo?.photo
                                ? brand?.photo?.photo?.thumb_url
                                : defaultBrandImage
                            }
                            alt="brand_logo"
                            className="w-24 h-24 rounded-lg object-contain"
                            loading="lazy"
                          />
                        </div>
                        <div className="flex flex-col gap-1 text-gray-900 justify-center mx-auto">
                          <p className="text-sm text-center">
                            Open Positions ({brand?.position_count})
                          </p>
                          <div
                            className={`text-sm cursor-pointer px-4 border flex items-center justify-center bg-gray-900 text-white hover:bg-white hover:border-gray-900 hover:text-black transition-all 
                                text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                            onClick={() => navigate(`/${brand?.brand_slug}`)}
                          >
                            View Positions
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex flex-col gap-0.5 text-gray-900">
                          <p className="font-extrabold">
                            {brand?.location?.name}
                          </p>
                          {/* <span className="text-[12px]">(0.3) mi away</span> */}
                          <p className="leading-3 mt-1">
                            <span className="font-bold text-[12px] mr-1.5">
                              Address:
                            </span>
                            <span className="text-[10px]">
                              {brand?.location?.address?.address},{" "}
                              {brand?.location?.address?.city},{" "}
                              {brand?.location?.address?.state},{" "}
                              {brand?.location?.address?.zip}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}

                {loading && (
                  <div className="justify-center w-full">
                    <div className="flex flex-col gap-4 mt-2 mx-2">
                      {Array.from({ length: 6 }).map((_, index) => (
                        <CardBusinessLoading key={index} />
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {filteredBrandsData &&
                filteredBrandsData?.length === 0 &&
                !loading &&
                !filterLoading && (
                  <div className="text-[14px] flex items-center justify-center p-2">
                    No data available
                  </div>
                )}
            </div>
          )}

          {selectedFilter === "Positions" && (
            <div className="h-[calc(100vh-13rem)] overflow-auto bg-[#f6f6f6] rounded-xl">
              <div className="flex flex-col m-4">
                {filteredPositionsData && filteredPositionsData.length > 0 && (
                  <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-6">
                    {filteredPositionsData?.map((item) => (
                      <div
                        className="bg-white rounded-2xl flex flex-col items-stretch text-center cursor-pointer shadow-xl"
                        onClick={() => {
                          navigate(`/${item?.brand_slug}`, {
                            state: {
                              positionData: JSON.stringify(item?.position),
                              location: {
                                ...item?.location?.address,
                                url_slug: item?.location_slug,
                              },
                              navigatePosition: true,
                            },
                          });
                        }}
                      >
                        <div className="border-b border-warmgray-400">
                          <img
                            src={
                              item?.photo?.photo
                                ? item?.photo?.photo?.thumb_url
                                : defaultBrandImage
                            }
                            alt="brand_logo"
                            className={`w-full h-36 lg:h-44 xl:h-40 2xl:h-56 rounded-t-2xl bg-white`}
                            loading="lazy"
                          />
                        </div>

                        <div className="py-1 flex flex-col justify-center items-center my-auto bg-white rounded-b-2xl">
                          <div className="text-[12px]">
                            {item?.location?.name}
                          </div>
                          <div className="font-bold">
                            {item?.position?.title}
                          </div>
                          <div className="text-[12px]">
                            <i className="fas fa-map-marker-alt text-pink-400 text-[10px]" />{" "}
                            {item?.location?.address?.city},{" "}
                            {item?.location?.address?.state}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {loading && (
                  <div className="flex">
                    <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 3xl:grid-cols-5 gap-6 w-full mt-2">
                      {Array.from({ length: 6 }).map((_, index) => (
                        <CardPositionLoading key={index} />
                      ))}
                    </div>
                  </div>
                )}

                {filteredPositionsData &&
                  filteredPositionsData?.length === 0 &&
                  !loading &&
                  !filterLoading && (
                    <div className="text-[14px] flex items-center justify-center p-2">
                      No data available
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
