import React from "react";
import { defaultBrandImage } from "../assets/images";

const CardPositionLoading = () => {
  return (
    <div className="shadow-lg rounded-xl p-4 bg-white">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1 my-2">
          <div className="flex flex-1 items-center justify-center">
            <div class="rounded-full bg-slate-200 h-16 w-16"></div>
          </div>
          <div className="h-2  bg-slate-200 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPositionLoading;
