import axios from "./api";

const Location = {
  getBySlug(slug) {
    return axios.get(`/public-profile/brand/${slug}`);
  },
  getLocationById(id) {
    return axios.get(`/public-profile/group/location/${id}`)
  }
}

export default Location;
