import React from "react";
import { defaultBrandImage } from "../assets/images";

const CardPositionMobileLoading = () => {
  return (
    <div className="shadow-lg rounded-xl p-4 bg-white">
      <div className="animate-pulse flex space-x-4">
        <div className="w-12 h-12 rounded-full bg-gray-300 flex-shrink-0">
          <div class="rounded-full bg-slate-200 h-12 w-12"></div>
        </div>
        <div className="flex-1 space-y-4 py-1">
          <div className="h-2 bg-slate-200 rounded w-3/4"></div>
          <div className="h-2 bg-slate-200 rounded w-1/2"></div>
        </div>
      </div>
    </div>
  );
};

export default CardPositionMobileLoading;
