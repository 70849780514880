import React, { useEffect, useState } from "react";
import {
  SelectedIcon,
  defaultBrandImage,
  heyhireNewBlackLogoIcon,
  heyhireNewLogoIcon,
  profileRingIcon,
} from "../../assets/images";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getPlatform } from "../../utils";
import Brand from "../../api/Brand";
import Location from "../../api/Location";
import "./GroupProfile.scss";

const GroupProfile = () => {
  const storedLocation = JSON.parse(localStorage.getItem("currentLocation"));
  const [error, setError] = useState(null);
  const [groupImage, setGroupImage] = useState(null);
  const [group, setGroup] = useState(null);
  const [platform, setPlatform] = useState("ios");
  const navigate = useNavigate();
  const params = useParams();
  const loc = useLocation();
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState();
  const [distance, setDistance] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("Positions");
  const [groupDetailsLoaded, setGroupDetailsLoaded] = useState(false);

  useEffect(() => {
    const _platform = getPlatform();
    setPlatform(_platform);
    if (params?.groupUrl && !groupDetailsLoaded) {
      setGroupDetailsLoaded(true);
      loadGroupDetails(params?.groupUrl);
    }
  }, [params, groupDetailsLoaded]);

  const loadGroupDetails = async (groupUrl) => {
    try {
      const response = await Brand.getGroupDetails(groupUrl);
      const groupData = response?.data?.data;
      setGroupImage(groupData?.group_photo?.original_url);
      setGroup(groupData?.group_details);

      const locations = JSON.parse(groupData.group_details.locations);

      // Fetch location details sequentially
      const locationDetails = [];
      for (const location of locations) {
        try {
          const locationResponse = await Location.getLocationById(
            location?.value
          );
          locationDetails.push(locationResponse?.data?.data);
        } catch (locationError) {
          console.error(
            "Error fetching location details:",
            locationError?.response?.data?.message
          );
        }
      }

      setLocations(locationDetails);
    } catch (error) {
      setError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (storedLocation && storedLocation?.city && storedLocation?.state) {
      if (location)
        handleCalculateDistance(
          storedLocation?.latitude,
          storedLocation?.longitude,
          location?.address?.lat,
          location?.address?.lng
        );
    }
  }, [storedLocation]);

  useEffect(() => {
    if (loc.pathname.includes("businesses")) {
      setSelectedFilter("Business Name");
    } else {
      navigate(`/group/${params?.groupUrl}/jobs`);
      setSelectedFilter("Positions");
    }
  }, [loc.pathname]);

  const handleCalculateDistance = (lat1, long1, lat2, long2) => {
    const R = 3958.8; // Earth radius in miles
    const numLat1 = parseFloat(lat1);
    const numLong1 = parseFloat(long1);
    const numLat2 = parseFloat(lat2);
    const numLong2 = parseFloat(long2);

    if (
      isNaN(numLat1) ||
      isNaN(numLong1) ||
      isNaN(numLat2) ||
      isNaN(numLong2)
    ) {
      console.error("Invalid coordinates provided");
      return;
    }

    const dLat = toRadians(numLat2 - numLat1);
    const dLon = toRadians(numLong2 - numLong1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(numLat1)) *
        Math.cos(toRadians(numLat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    setDistance(distance.toFixed(2));
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    if (filter === "Positions") {
      navigate(`/group/${params?.groupUrl}/jobs`);
    } else if (filter === "Business Name") {
      navigate(`/group/${params?.groupUrl}/businesses`);
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="w-full fixed z-10 bg-white flex-shrink-0">
        <div className="h-16 md:h-20 top-0 w-full bg-gray-900 flex md:rounded-b-2xl z-[1]">
          <div className="m-4 px-4 md:px-0 mx-auto my-auto w-full">
            <div className="flex justify-between w-full md:w-[75%] xl:w-[80%] ml-auto md:px-6 xl:px-24 custom-screen-group-size">
              <div className="flex flex-col justify-center md:items-center cursor-pointer">
                <img
                  className="w-28 md:w-36"
                  src={heyhireNewLogoIcon}
                  alt="heyhire"
                  onClick={() => {
                    navigate("/jobs");
                  }}
                />
                <span className="font-bold text-[8px] w-28 md:w-36 flex justify-end text-white">
                  {storedLocation?.city}, {storedLocation?.state}
                </span>
              </div>

              <div className="my-auto">
                <div
                  className="cursor-pointer px-6 flex items-center justify-center bg-white text-black hover:bg-gray-900 hover:text-white hover:border-white border transition-all text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-2"
                  onClick={() =>
                    window.open("https://www.heyhire.com/", "_blank")
                  }
                >
                  Post a Job
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-20 md:flex hidden h-screen">
        <div className="w-[25%] xl:w-[20%] bg-white p-4 border-r border-warmgray-300 flex flex-col rounded-br-2xl">
          <div className="relative flex justify-center bg-white rounded-full p-1 w-36 h-36 mx-auto my-4">
            <img
              className="rounded-full object-contain"
              src={groupImage ? groupImage : defaultBrandImage}
              alt="heyhire"
            />
            <img
              src={profileRingIcon}
              alt="avatar"
              className="absolute inset-0"
            />
          </div>
          <div className="text-center">
            <p className="font-extrabold text-[20px]">{group?.group_name}</p>
            <div className="flex flex-col my-6 gap-1.5">
              <p
                className="font-arial font-semibold text-[15px] cursor-pointer"
                onClick={() =>
                  window.open(
                    (group?.website_url.startsWith("http") ? "" : "https://") +
                      group?.website_url,
                    "_blank",
                    "noopener noreferrer"
                  )
                }
              >
                {group?.website_url}
              </p>
            </div>
            <hr className="w-full border border-warmgray-200" />
          </div>
          <div>
            <p className="font-extrabold my-4">About {group?.group_name}:</p>
            <p className="text-[14px] font-arial">{group?.description}</p>
          </div>
          <div className="mt-auto mb-6">
            <div className="flex justify-center mb-8">
              <button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                  )
                }
              >
                <img
                  width={130}
                  src="/images/play_store_badge.png"
                  alt="Play Store"
                />
              </button>
              <button
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/heyhire/id1628579684"
                  )
                }
              >
                <img
                  width={120}
                  className="ml-2"
                  src="/images/app_store_badge.png"
                  alt="App Store"
                />
              </button>
            </div>
            <div className="py-4 flex justify-center">
              <img
                height={180}
                width={180}
                alt="logo"
                src={heyhireNewBlackLogoIcon}
              />
            </div>
          </div>
        </div>

        <div className="w-[75%] xl:w-[80%] bg-white overflow-y-auto md:px-6 xl:px-24 custom-screen-group-size">
          <div className="bg-white rounded-br-3xl py-6 xl:py-10 text-center border border-warmgray-200 shadow-lg">
            <p className="font-extrabold text-xl md:text-2xl xl:text-3xl">
              <span className="text-gray-900">Join our team! </span>
              <span className="text-[#BC74A9]">Apply in just a few taps!</span>
            </p>
          </div>

          <div className="flex flex-col mt-4 gap-2 lg:flex-row">
            <p className="text-[13px] font-semibold my-auto">Sort By:</p>
            <div className="flex flex-wrap whitespace-nowrap gap-1.5 text-sm">
              <div
                className={`cursor-pointer px-6 flex items-center justify-center bg-gray-900 text-white ${
                  selectedFilter === "Positions" ? "font-bold" : "font-normal"
                } text-center whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                onClick={() => handleFilterClick("Positions")}
              >
                {selectedFilter === "Positions" && (
                  <img src={SelectedIcon} alt="logo" className="mr-2 my-auto" />
                )}
                Positions
              </div>
              <div
                className={`cursor-pointer px-6 flex items-center justify-center bg-gray-900 text-white ${
                  selectedFilter === "Business Name"
                    ? "font-bold"
                    : "font-normal"
                } text-center whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                onClick={() => handleFilterClick("Business Name")}
              >
                {selectedFilter === "Business Name" && (
                  <img src={SelectedIcon} alt="logo" className="mr-2 my-auto" />
                )}
                Business Name
              </div>
            </div>
          </div>

          <div className="bg-[#f6f6f6] p-4 my-6">
            {selectedFilter === "Positions" && (
              <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 gap-6">
                {locations?.map((location) => (
                  <React.Fragment key={location.id}>
                    {location.positions
                      .filter((position) => position.archived_at === null)
                      .map((position) => (
                        <div
                          className="bg-white shadow-sm border border-warmgray-200 rounded-lg flex flex-col items-stretch text-center cursor-pointer"
                          key={position.id}
                          onClick={() => {
                            const encodedTitle = encodeURIComponent(
                              position?.title
                            );
                            navigate(
                              `/${location?.brand?.url_slug}/${location?.url_slug}/${encodedTitle}`,
                              { state: { groupName: params?.groupUrl } }
                            );
                          }}
                        >
                          <div className="border-b border-warmgray-400">
                            <img
                              src={
                                location.brand?.photo
                                  ? location.brand?.photo?.thumb_url
                                  : defaultBrandImage
                              }
                              alt="brand_logo"
                              className={`w-full h-40 rounded-t-2xl bg-white`}
                              loading="lazy"
                            />
                          </div>

                          <div className="p-1 flex flex-col justify-center items-center my-auto">
                            <div className="text-[12px]">{location.name}</div>
                            <div className="font-bold">{position.title}</div>
                            <div className="text-[12px]">
                              <i className="fas fa-map-marker-alt text-pink-400 text-[10px]" />{" "}
                              {location.address?.city},{" "}
                              {location.address?.state}
                            </div>
                          </div>
                        </div>
                      ))}
                  </React.Fragment>
                ))}
              </div>
            )}

            {selectedFilter === "Business Name" && (
              <div className="flex flex-col gap-4">
                {locations?.map((item) => (
                  <div className="bg-white flex gap-2 p-4 justify-between shadow-sm border border-warmgray-200 rounded-lg">
                    <div className="flex flex-col flex-grow">
                      <div className="flex flex-col gap-0.5 text-gray-900 mb-2">
                        <p className="font-extrabold tracking-wide">
                          {item?.name}
                        </p>
                        <p className="mt-1">
                          <span className="font-bold text-[14px] mr-1.5">
                            Address:
                          </span>
                          <span className="text-[12px]">
                            {item.address?.address}, {item?.address?.city},{" "}
                            {item?.address?.state}, {item?.address?.zip}
                          </span>
                        </p>
                        <p className="text-[14px]">
                          Open Positions (
                          {item?.positions
                            ? item?.positions.filter(
                                (job) => job?.archived_at === null
                              ).length
                            : "0"}
                          )
                        </p>
                        {item?.positions?.length > 0 && (
                          <ul className="flex flex-wrap list-none gap-3 mx-4 pb-4">
                            {item?.positions
                              ?.filter((job) => job?.archived_at === null)
                              .map((job, index) => (
                                <li
                                  key={index}
                                  className="text-[12px] list-disc mr-2 text-gray-600 ml-1"
                                >
                                  {job.title}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                      <div className="mt-auto">
                        <div
                          className={`w-48 cursor-pointer px-6 border flex items-center justify-center bg-gray-900 text-white hover:bg-white transition-all hover:border-black hover:text-black
                          text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                          onClick={() =>
                            navigate(`/${item?.brand?.url_slug}`, {
                              state: { groupName: params?.groupUrl },
                            })
                          }
                        >
                          View Positions
                        </div>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <img
                        src={
                          item?.brand?.photo
                            ? item?.brand?.photo?.thumb_url
                            : defaultBrandImage
                        }
                        alt="brand_logo"
                        className="w-44 h-44 object-contain rounded-lg"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="h-screen pt-16 flex md:hidden flex-col bg-white">
        <div className="bg-white">
          <div className="py-6 text-center border border-warmgray-200 rounded-b-2xl shadow-md">
            <p>
              <span className="text-lg text-gray-900 font-extrabold">
                Join our team!{" "}
              </span>
              <span className="text-lg text-[#BC74A9] font-extrabold">
                Apply in just a few taps!
              </span>
            </p>
          </div>

          <div className="z-0 relative flex justify-center bg-white rounded-full p-1 w-32 h-32 mx-auto my-4">
            <img
              className="rounded-full object-contain"
              src={groupImage ? groupImage : defaultBrandImage}
            />
            <img
              src={profileRingIcon}
              alt="avatar"
              className="absolute inset-0"
            />
          </div>

          <div className="text-center">
            <p className="font-extrabold text-[16px]">{group?.group_name}</p>
            <div className="flex flex-col mb-4 mt-2 px-4">
              <p
                className="font-arial font-bold text-[10px] cursor-pointer"
                onClick={() =>
                  window.open(
                    group?.website_url,
                    "_blank",
                    "noopener noreferrer"
                  )
                }
              >
                {group?.website_url}
              </p>
            </div>
          </div>

          <div className="pb-4 border-t-[0.5px] border-warmgray-300 px-4">
            <p className="font-bold mb-2 mt-4 text-[16px]">
              About {group?.group_name}:
            </p>
            <p className="text-[12px] font-arial">{group?.description}</p>
          </div>

          <div className="flex flex-col my-4 gap-2 lg:flex-row mx-4">
            <p className="text-[13px] font-semibold my-auto">Sort By:</p>
            <div className="flex flex-wrap whitespace-nowrap gap-1.5 text-sm">
              <div
                className={`cursor-pointer px-6 flex items-center justify-center bg-gray-900 text-white ${
                  selectedFilter === "Positions" ? "font-bold" : "font-normal"
                } text-center whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                onClick={() => handleFilterClick("Positions")}
              >
                {selectedFilter === "Positions" && (
                  <img src={SelectedIcon} alt="logo" className="mr-2 my-auto" />
                )}
                Positions
              </div>
              <div
                className={`cursor-pointer px-6 flex items-center justify-center bg-gray-900 text-white ${
                  selectedFilter === "Business Name"
                    ? "font-bold"
                    : "font-normal"
                } text-center whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                onClick={() => handleFilterClick("Business Name")}
              >
                {selectedFilter === "Business Name" && (
                  <img src={SelectedIcon} alt="logo" className="mr-2 my-auto" />
                )}
                Business Name
              </div>
            </div>
          </div>
        </div>

        <div className="mx-2 mt-2 mb-8">
          {selectedFilter === "Positions" && (
            <div className="p-4 bg-[#f6f6f6] rounded-xl">
              <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 gap-6">
                {locations?.map((location) => (
                  <React.Fragment key={location.id}>
                    {location.positions
                      .filter((position) => position.archived_at === null)
                      .map((position) => (
                        <div
                          className="bg-white rounded-xl flex flex-col items-stretch text-center cursor-pointer shadow-lg"
                          onClick={() => {
                            const encodedTitle = encodeURIComponent(
                              position?.title
                            );
                            navigate(
                              `/${location?.brand?.url_slug}/${location?.url_slug}/${encodedTitle}`,
                              { state: { groupName: params?.groupUrl } }
                            );
                          }}
                        >
                          <div className="border-b border-warmgray-400 h-40">
                            <img
                              src={
                                location?.brand?.photo
                                  ? location?.brand?.photo?.thumb_url
                                  : defaultBrandImage
                              }
                              alt="brand_logo"
                              className="w-full h-full rounded-t-2xl bg-white"
                            />
                          </div>
                          <div className="my-auto flex flex-col justify-center items-center py-1">
                            <div className="text-[10px]">{location.name}</div>
                            <div className="font-bold text-[14px]">
                              {position?.title}
                            </div>
                            <div className="text-[10px]">
                              <i className="fas fa-map-marker-alt text-pink-400 text-[10px]" />{" "}
                              {location.address?.city},{" "}
                              {location.address?.state}
                            </div>
                          </div>
                        </div>
                      ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}

          {selectedFilter === "Business Name" && (
            <div className="bg-[#f6f6f6] rounded-xl p-2">
              {locations?.map((item) => (
                <div className="bg-white flex flex-col gap-2 my-2 p-4 justify-between shadow-sm border border-warmgray-200 rounded-lg">
                  <div className="flex gap-4">
                    <div>
                      <img
                        src={
                          item?.brand?.photo
                            ? item?.brand?.photo?.thumb_url
                            : defaultBrandImage
                        }
                        alt="brand_logo"
                        className="w-28 h-24 rounded-lg"
                        loading="lazy"
                      />
                    </div>
                    <div className="flex flex-col gap-1 text-gray-900 justify-center mx-auto">
                      <p className="text-sm">
                        Open Positions (
                        {item?.positions
                          ? item?.positions.filter(
                              (job) => job?.archived_at === null
                            ).length
                          : "0"}
                        )
                      </p>
                      <div
                        className={`text-sm cursor-pointer px-4 border flex items-center justify-center bg-gray-900 text-white hover:bg-white bg:text-black hover:border-black transition-all hover:opacity-50
                            text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-1.5`}
                        onClick={() =>
                          navigate(`/${item?.brand?.url_slug}`, {
                            state: { groupName: params?.groupUrl },
                          })
                        }
                      >
                        View Positions
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex flex-col gap-0.5 text-gray-900">
                      <p className="font-extrabold">{item?.name}</p>
                      <p className="mt-1">
                        <span className="font-bold text-[12px] mr-1.5">
                          Address:
                        </span>
                        <span className="text-[10px]">
                          {item.address?.address}, {item?.address?.city},{" "}
                          {item?.address?.state}, {item?.address?.zip}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="bg-white justify-center text-center p-2 mt-auto flex border-t border-warmgray-200">
          <p className="my-auto mr-2 text-sm">
            Want to <span className="font-bold">apply</span> to this{" "}
            <span className="font-bold">business</span>?
          </p>
          <button
            onClick={() => {
              if (platform == "ios") {
                window.open(
                  "https://apps.apple.com/us/app/heyhire/id1628579684"
                );
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                );
              }
            }}
          >
            <img
              width={80}
              className="ml-4"
              src={
                platform === "ios"
                  ? "/images/app_store_badge.png"
                  : "/images/play_store_badge.png"
              }
              alt="App Store"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GroupProfile;
