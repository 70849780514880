import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Space } from "antd";
import "./BrandEmbeddedProfile.scss";
import EmbeddedOpenPositionCard from "../../components/EmbeddedOpenPositionCard/EmbeddedOpenPositionCard";
import Brand from "../../api/Brand";
import { PoweredByWhite } from "../../assets/images";
import { getPlatform } from "../../utils";

const BrandEmbeddedProfile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const passedProp = useLocation();
  const [brand, setBrand] = useState(passedProp?.state?.brand);
  const [error, setError] = useState(null);
  const [platform, setPlatform] = useState("ios");
  const theme = passedProp?.search.split("=")[1];

useEffect(() => {
  const root = window.document.documentElement;
  if (theme === 'dark') {
    root.classList.remove('light');
    root.classList.add('dark');    
  } else {
    root.classList.remove('dark');
    root.classList.add('light');
  }
  const _platform = getPlatform();
  setPlatform(_platform);
  if(!brand) {
    loadBrand();
  }
}, []);

  const loadBrand = async () => {
    await Brand.getBrand(params.brandSlug)
    .then((response) => {
      document.title = `Job openings at ${response?.data?.data?.company?.name}, Now Hiring! - Powered By HeyHire`;
      const tempObj = response.data.data;
      setBrand(tempObj);
      setError(null);
      if (tempObj?.location_count === 1) {
        navigate(`/embed/${tempObj.url_slug}/${tempObj.locations[0].url_slug}`, {state: {brand: tempObj} });
      }
    }).catch((error) => {
      setError(error?.response?.data?.message);
    });
  }

  const renderLocation = (location) => {
    const _positions = location?.positions?.filter(i => i.archived_at === null);
    return(
      <div className="mb-20">
        <div className="my-3 md:my-4">
          {_positions?.map((item, i) => (
            <Space direction="vertical" size={8} className="w-full">
              <EmbeddedOpenPositionCard position={item} />
            </Space>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="page-public-profile h-auto bg-gray-300 dark:bg-neutral-800">
      <div className="public-profile flex flex-col h-full md:pt-20 pt-2 md:mx-40 mx-2">
        <div className="flex flex-col justify-center items-center h-60 bg-gray-200 dark:bg-neutral-600 md:h-32 bg-white rounded-t-2xl md:rounded-t-3xl">
          <div className="flex flex-1 flex-col justify-center items-center mx-auto p-4">
            <span className="font-semibold text-center text-2xl text-black dark:text-white">We’d love to have you join our team! </span>
            <br/>
            <span className="font-semibold text-sm text-center text-black dark:text-white">Please use the form below to apply</span>
            <span className="text-sm text-center text-black dark:text-white">Feel free to send in your resume even if you don't see the position you're looking for listed.</span>
          </div>
        </div>
        <div className="bg-white dark:bg-neutral-700 w-full mx-auto flex-1">
          <div className="flex h-full flex-col md:flex-row">
            <div className="flex-1 justify-center items-center md:pt-8 p-4 pb-44 md:pb-6">
              {!error ?
                (
                  <div>
                    {brand?.locations.map((location) => {
                        return (
                          renderLocation(location)
                        );
                    })}
                  </div>
                ) : (
                  <div className="flex w-full h-full justify-center items-center">
                    <span className="text-slate-500 text-xl md:text-6xl font-black tracking-widest decoration-slate-500 antialiased">{error}</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>

      <div className={"flex flex-1 md:flex-row flex-col justify-center md:w-auto lg:w-auto bg-primary-500 dark:bg-neutral-900 w-full md:mx-40 lg:mx-40 mx-auto"}>
        <div className="flex flex-1 justify-start text-center py-4 justify-center">
          <span className="font-semibold text-white self-center mr-2">Powered by </span>
          <img height={10} width={120} alt="logo" src={PoweredByWhite} />
        </div>
        <div
          className={
            "flex flex-1 py-5 flex hidden lg:block items-center justify-end mr-20"
          }
        >
          <button onClick={() => window.open("https://play.google.com/store/apps/details?id=com.heyhire&pli=1")}>
            <img
              width={150}
              className="ml-4"
              src="/images/play_store_badge.png"
              alt="Play Store"
            />
          </button>
          <button onClick={() => window.open("https://apps.apple.com/us/app/heyhire/id1628579684")}>
            <img
              width={150}
              className="ml-4"
              src="/images/app_store_badge.png"
              alt="App Store"
            />
          </button>
        </div>
        <div
          className={
            "flex flex-1 py-5 flex items-center justify-center lg:hidden"
          }
        >
          <button
            onClick={() => {
              if (platform == "ios") {
                window.open("https://apps.apple.com/us/app/heyhire/id1628579684");
              } else {
                window.open("https://play.google.com/store/apps/details?id=com.heyhire&pli=1");
              }
            }}
          >
            <img
              width={150}
              className="ml-4"
              src={platform === "ios" ? "/images/app_store_badge.png" : "/images/play_store_badge.png"}
              alt="App Store"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandEmbeddedProfile;
