import React, { useEffect, useState } from "react";
import HeyHireLogo from "../../assets/images/powered-by.png";
import {
  defaultBrandImage,
  heyhireNewBlackLogoIcon,
  heyhireNewLogoIcon,
  leftArrowImg,
  profileRingIcon,
  toastyImage,
} from "../../assets/images";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LocationImg, defaultUserImage } from "../../assets/images";
import ImgAvatarArrow from "../../assets/images/profile-round.svg";
import { Collapse, Space } from "antd";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { getPlatform } from "../../utils";
import Brand from "../../api/Brand";
import Analytics from "../../api/Analytics";

const { Panel } = Collapse;

const BusinessProfile = () => {
  const location = useLocation();
  const [brand, setBrand] = useState(location?.state?.brand);
  const [error, setError] = useState(null);
  const [platform, setPlatform] = useState("ios");
  const navigate = useNavigate();
  const params = useParams();
  const storedLocation = JSON.parse(localStorage.getItem("currentLocation"));
  const navigatePosition = location.state && location.state.navigatePosition;
  const groupName = location.state && location.state.groupName;
  const positionData =
    location.state &&
    location.state.positionData &&
    JSON.parse(location.state.positionData);
  const certificates = location.state && location.state.certificates;
  const locationForPosition = location.state && location.state.location;
  const ogImageUrl = brand?.photo ? brand?.photo?.thumb_url : defaultBrandImage;

  useEffect(() => {
    const _platform = getPlatform();
    setPlatform(_platform);
    if (!brand) {
      loadBrand();
    }
  }, []);

  const analytics = () => {
    const data = {
      brand_url_slug: params.businessName,
      details: navigator.userAgent,
    };
    const capture = Analytics.trackAnalytics(data);
  };

  const loadBrand = async () => {
    await Brand.getBrand(params.businessName)
      .then((response) => {
        const tempObj = response.data.data;
        document.title = `Job openings at ${response?.data?.data?.company?.name}, Now Hiring! - Powered By HeyHire`;

        setBrand(tempObj);
        setError(null);
        if (navigatePosition) {
          if (tempObj?.location_count === 1) {
            const encodedTitle = encodeURIComponent(positionData?.title);
            const locationData = {
              ...locationForPosition,
              certificates: certificates,
            };
            navigate(
              `/${tempObj.url_slug}/${tempObj.locations[0].url_slug}/${encodedTitle}`,
              {
                state: {
                  position: positionData,
                  location: locationData,
                  multipleLocation: false,
                  navigatePosition: true,
                },
              }
            );
          } else {
            const encodedTitle = encodeURIComponent(positionData?.title);
            const locationData = {
              ...locationForPosition,
              certificates: certificates,
            };
            navigate(
              `/${tempObj.url_slug}/${locationForPosition?.url_slug}/${encodedTitle}`,
              {
                state: {
                  position: positionData,
                  location: locationData,
                  multipleLocation: true,
                  navigatePosition: true,
                },
              }
            );
          }
        } else {
          if (tempObj?.location_count === 1) {
            navigate(`/${tempObj.url_slug}/${tempObj.locations[0].url_slug}`, {
              state: { brand: tempObj, group: groupName },
            });
          }
        }
        analytics();
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
      });
  };

  const handleDistance = (location) => {
    if (
      storedLocation &&
      storedLocation?.latitude &&
      storedLocation?.longitude
    ) {
      return handleCalculateDistance(
        storedLocation?.latitude,
        storedLocation?.longitude,
        location?.address?.lat,
        location?.address?.lng
      );
    }
  };

  const handleCalculateDistance = (lat1, long1, lat2, long2) => {
    const R = 3958.8; // Earth radius in miles
    const numLat1 = parseFloat(lat1);
    const numLong1 = parseFloat(long1);
    const numLat2 = parseFloat(lat2);
    const numLong2 = parseFloat(long2);

    if (
      isNaN(numLat1) ||
      isNaN(numLong1) ||
      isNaN(numLat2) ||
      isNaN(numLong2)
    ) {
      console.error("Invalid coordinates provided");
      return;
    }

    const dLat = toRadians(numLat2 - numLat1);
    const dLon = toRadians(numLong2 - numLong1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(numLat1)) *
        Math.cos(toRadians(numLat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance.toFixed(2);
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="w-full fixed z-10 bg-white flex-shrink-0">
        <div className="h-16 md:h-20 top-0 w-full bg-gray-900 flex md:rounded-b-2xl z-[1]">
          <div className="m-4 px-4 md:px-0 mx-auto my-auto w-full">
            <div className="flex justify-between w-full md:w-[75%] xl:w-[80%] ml-auto md:px-6 xl:px-24 2xl:px-36">
              <div className="flex flex-col justify-center md:items-center cursor-pointer">
                <img
                  className="w-28 md:w-36"
                  src={heyhireNewLogoIcon}
                  alt="heyhire"
                  onClick={() => {
                    navigate("/jobs");
                  }}
                />
                <span className="font-bold text-[8px] w-28 md:w-36 flex justify-end text-white">
                  {storedLocation?.city}, {storedLocation?.state}
                </span>
              </div>

              <div className="my-auto">
                <div
                  className="cursor-pointer px-6 flex items-center justify-center bg-white text-black hover:bg-gray-900 hover:text-white hover:border-white border transition-all text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-2"
                  onClick={() =>
                    window.open("https://www.heyhire.com/", "_blank")
                  }
                >
                  Post a Job
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-20 md:flex hidden h-screen">
        <div className="w-[25%] xl:w-[20%] bg-white p-4 border-r border-warmgray-300 flex flex-col rounded-br-2xl">
          <div className="relative flex justify-center bg-white rounded-full p-1 w-36 h-36 mx-auto my-4">
            <img
              className="rounded-full object-contain"
              src={brand?.photo ? brand?.photo?.thumb_url : defaultBrandImage}
              alt="heyhire"
            />
            <img
              src={profileRingIcon}
              alt="avatar"
              className="absolute inset-0"
            />
          </div>
          <div className="text-center">
            <p className="font-extrabold text-[20px]">{brand?.name}</p>
            <div className="flex flex-col my-6 gap-1.5">
              <p
                className="font-arial font-semibold text-[15px] cursor-pointer"
                onClick={() =>
                  window.open(brand?.website, "_blank", "noopener noreferrer")
                }
              >
                {brand?.website}
              </p>
            </div>
            <hr className="w-full border border-warmgray-200" />
          </div>
          <div>
            <p className="font-extrabold my-4">About {brand?.name}:</p>
            <p className="text-[14px] font-arial">{brand?.description}</p>
          </div>
          <div className="mt-auto mb-4">
            <div className="flex justify-center mb-6">
              <button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                  )
                }
              >
                <img
                  width={130}
                  src="/images/play_store_badge.png"
                  alt="Play Store"
                />
              </button>
              <button
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/heyhire/id1628579684"
                  )
                }
              >
                <img
                  width={120}
                  className="ml-2"
                  src="/images/app_store_badge.png"
                  alt="App Store"
                />
              </button>
            </div>
            <div className="py-4 flex justify-center">
              <img
                height={180}
                width={180}
                alt="logo"
                src={heyhireNewBlackLogoIcon}
              />
            </div>
          </div>
        </div>

        <div className="w-[75%] xl:w-[80%] bg-white overflow-y-auto md:px-6 xl:px-24 2xl:px-36">
          <div className="bg-white rounded-br-3xl py-6 xl:py-10 text-center border border-warmgray-200 shadow-lg">
            <p className="font-extrabold text-xl md:text-2xl xl:text-3xl">
              <span className="text-gray-900">Join our team! </span>
              <span className="text-[#BC74A9]">Apply in just a few taps!</span>
            </p>
          </div>

          <div className="my-6">
            <div
              className="flex cursor-pointer"
              onClick={() =>
                groupName ? navigate(`/group/${groupName}`) : navigate("/jobs")
              }
            >
              <img src={leftArrowImg} className="w-4 h-4 my-auto mr-2" />
              <span className="text-[15px] font-normal">
                {groupName ? "Groups" : "Job search"}
              </span>
            </div>

            {brand?.locations?.map((location) => (
              <>
                <div className="mt-10">
                  <div className="flex justify-between bg-[#AA73A6] px-4 py-2.5 rounded-md mb-4">
                    <div className="text-[14px] text-white font-bold relative">
                      <i className="fas fa-map-marker-alt text-white text-[14px] mr-1.5" />
                      {location?.name}
                      <span className="border-r border-white h-4 inline-block align-middle ml-3 mr-2" />{" "}
                      {/* Separator line */}
                      {location?.address?.address}
                    </div>
                    {storedLocation && (
                      <p className="font-arial font-semibold text-[14px] text-white">
                        {handleDistance(location)} mi away
                      </p>
                    )}
                  </div>
                  <p className="font-extrabold text-[20px]">
                    Currently Hiring For:
                  </p>
                  <p className="mt-2 text-[14px] font-arial">
                    Tap position to view information & apply!
                  </p>
                </div>

                <div className="bg-[#f6f6f6] rounded-xl mt-8 p-4">
                  {location?.positions?.filter(
                    (position) => position?.archived_at === null
                  ).length > 0 ? (
                    <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 gap-6">
                      {location?.positions
                        ?.filter((position) => position?.archived_at === null)
                        .map((position) => (
                          <div
                            className="rounded-2xl flex flex-col items-stretch text-center cursor-pointer shadow-xl"
                            onClick={() => {
                              const encodedTitle = encodeURIComponent(
                                position?.title
                              );
                              navigate(
                                `/${params.businessName}/${location?.url_slug}/${encodedTitle}`,
                                {
                                  state: {
                                    position,
                                    location,
                                    multipleLocation: true,
                                  },
                                }
                              );
                            }}
                          >
                            <div className="h-40 border-b border-warmgray-400">
                              <img
                                src={
                                  brand?.photo
                                    ? brand?.photo?.thumb_url
                                    : defaultBrandImage
                                }
                                alt="brand_logo"
                                className="w-full h-full rounded-t-2xl bg-white"
                              />
                            </div>
                            <div className="h-16 flex flex-col justify-center items-center py-1 bg-white rounded-b-2xl">
                              <div className="font-bold text-[16px]">
                                {position?.title}
                              </div>
                              <div className="text-[12px]">
                                {position?.hiring_status === 1
                                  ? "Immediately Hiring"
                                  : position?.hiring_status === 2
                                  ? "Externally Hiring"
                                  : "Accepting Applications"}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="text-center text-[14px] font-bold my-2">
                      No Position Available
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="h-screen pt-16 flex md:hidden flex-col bg-white">
        <div className="bg-white border-b border-warmgray-200 rounded-b-2xl shadow-md z-0">
          <div className="py-6 text-center border border-warmgray-200 rounded-b-2xl shadow-md">
            <p>
              <span className="text-xl text-gray-900 font-extrabold">
                Join our team!{" "}
              </span>
              <span className="text-xl text-[#BC74A9] font-extrabold">
                Apply in just a few taps!
              </span>
            </p>
          </div>

          <div
            className="flex cursor-pointer px-4 mt-2"
            onClick={() =>
              groupName ? navigate(`/group/${groupName}`) : navigate("/jobs")
            }
          >
            <img src={leftArrowImg} className="w-3 h-3 my-auto mr-2" />
            <span className="text-[12px] font-normal">
              {groupName ? "Groups" : "Job search"}
            </span>
          </div>

          <div className="z-0 relative flex justify-center bg-white rounded-full p-1 w-32 h-32 mx-auto my-4">
            <img
              className="rounded-full object-contain"
              src={brand?.photo ? brand?.photo?.thumb_url : defaultBrandImage}
            />
            <img
              src={profileRingIcon}
              alt="avatar"
              className="absolute inset-0"
            />
          </div>

          <div className="text-center">
            <p className="font-extrabold text-[16px]">{brand?.name}</p>
            <div className="flex flex-col mb-4 mt-2">
              {/* <p className="font-arial font-bold text-[10px]"><span><i className="fas fa-map-marker-alt text-black text-[10px] my-auto" /></span>{" "}{location?.address?.address}</p> */}
              <p
                className="font-arial font-bold text-[10px] cursor-pointer"
                onClick={() =>
                  window.open(brand?.website, "_blank", "noopener noreferrer")
                }
              >
                {brand?.website}
              </p>
            </div>
            <hr className="w-full border-[0.5px] border-warmgray-200" />
          </div>

          <div className="pb-4 px-4">
            <p className="font-bold mb-2 mt-4 text-[16px]">
              About {brand?.name}:
            </p>
            <p className="text-[12px] font-arial">{brand?.description}</p>
          </div>
        </div>

        {brand?.locations?.map((location) => (
          <div className="pt-6 px-4 bg-white mb-6">
            <div className="flex flex-col items-center justify-center bg-[#AA73A6] p-2 rounded-md mb-4 gap-0.5">
              <div className="text-[12px] text-white font-bold relative">
                <i className="fas fa-map-marker-alt text-white text-[12px] mr-1.5" />
                {location?.name}
              </div>
              <p className="font-arial font-semibold text-[12px] text-white -mt-0.5">
                {location?.address?.address}
              </p>
              {storedLocation && (
                <p className="font-arial font-semibold text-[12px] text-white -mt-1">
                  {handleDistance(location)} mi away
                </p>
              )}
            </div>

            <p className="font-extrabold text-[14px]">Currently Hiring For:</p>
            <p className="mt-1 text-[12px] font-arial mb-2">
              Tap position to view information & apply!
            </p>

            {location?.positions?.filter(
              (position) => position?.archived_at === null
            ).length > 0 ? (
              <div className="flex flex-col gap-2 mb-4 bg-[#f6f6f6] rounded-xl p-2">
                {location?.positions
                  ?.filter((position) => position?.archived_at === null)
                  .map((position) => (
                    <div
                      className="bg-white border border-warmgray-200 rounded-lg flex p-2 gap-2 cursor-pointer shadow-md"
                      onClick={() => {
                        const encodedTitle = encodeURIComponent(
                          position?.title
                        );
                        navigate(
                          `/${params.businessName}/${location?.url_slug}/${encodedTitle}`,
                          {
                            state: {
                              position,
                              location,
                              multipleLocation: true,
                            },
                          }
                        );
                      }}
                    >
                      <div className="">
                        <img
                          src={
                            brand?.photo
                              ? brand?.photo?.thumb_url
                              : defaultBrandImage
                          }
                          alt=""
                          className="w-10 h-10 rounded-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <p className="font-bold text-[14px]">
                          {position?.title}
                        </p>
                        <p className="text-[10px]">
                          {position?.hiring_status === 1
                            ? "Immediately Hiring"
                            : position?.hiring_status === 2
                            ? "Externally Hiring"
                            : "Accepting Applications"}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="text-center text-[12px] mb-2 mt-4 bg-[#f6f6f6] p-2">
                No Position Available
              </div>
            )}
          </div>
        ))}

        <div className="bg-white justify-center text-center p-2 mt-auto flex border-t border-warmgray-200">
          <p className="my-auto mr-2 text-sm">
            Want to <span className="font-bold">apply</span> to this{" "}
            <span className="font-bold">business</span>?
          </p>
          <button
            onClick={() => {
              if (platform == "ios") {
                window.open(
                  "https://apps.apple.com/us/app/heyhire/id1628579684"
                );
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                );
              }
            }}
          >
            <img
              width={80}
              className="ml-4"
              src={
                platform === "ios"
                  ? "/images/app_store_badge.png"
                  : "/images/play_store_badge.png"
              }
              alt="App Store"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;
