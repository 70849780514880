import axios from "./api";

const QrLookUp = {
  getByuuid(uuid) {
    return axios.get(`/business/qr-code-uuid/${uuid}`);
  },
  getByPreviousid(id) {
    return axios.get(`/business/qr-code-previous/${id}`);
  }
}

export default QrLookUp;