import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Location from "../../api/Location";
import "./LocationProfile.scss";
import OpenPositionCard from "../../components/OpenPositionCard/OpenPositionCard";
import { Collapse, Space } from "antd";
import ImgAvatarArrow from "../../assets/images/profile-round.svg";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { defaultUserImage } from "../../assets/images";
import { getPlatform } from "../../utils";
import Banner from "../../components/Banner/Banner";
import Analytics from "../../api/Analytics";

const { Panel } = Collapse;

const LocationProfile = () => {
  const passedProp = useLocation();
  const [positions, setpositions] = useState([]);
  const [location, setLocation] = useState();
  const [error, setError] = useState(null);
  const [brand, setBrand] = useState(passedProp?.state?.brand);
  const [platform, setPlatform] = useState("ios");
  const params = useParams();
  const ogImageUrl = brand?.photo ? brand?.photo?.thumb_url : defaultUserImage;

  useEffect(() => {
    const _platform = getPlatform();
    setPlatform(_platform);
    analytics();
    loadPositions();
  }, []);

  const analytics = () => {
     const data = {
       brand_url_slug: params.brandSlug,
       location_url_slug: params.locationSlug,
       details: navigator.userAgent,
     };
     const capture = Analytics.trackAnalytics(data);
  }

  const loadPositions = () => {
    Location.getBySlug(`${params.brandSlug}/${params.locationSlug}`)
    .then((response) => {
      document.title = `Job openings at ${response?.data?.data?.name}, Now Hiring! - Powered By HeyHire`;

      const _positions = response.data.data.positions;
      const filterJobs = _positions.filter(i => i.archived_at === null);
      setpositions(filterJobs);
      if (response.data.data?.brand) {
        setBrand(response.data.data?.brand);
      }
      setLocation(response.data.data);
    }).catch((error) => {
      setError(error?.response?.data?.message);
    });
  }

  return (
    <div className="page-public-profile h-full bg-gray-300">
      <div className="public-profile flex flex-col h-full">
        <Banner />
        <div className="max-w-[1280px] w-full mx-auto flex-1">
          <div className="flex h-full flex-col lg:flex-row">
            <div className="w-full lg:w-[383px] bg-primary-700 text-white flex flex-col">
              <div className="relative hidden top-[-50px] lg:flex flex-col px-6 h-100">
                <div className="relative flex justify-center bg-white rounded-full p-3 w-[145px] h-[145px] mx-auto">
                  <img
                    className={"rounded-full"}
                    height="125"
                    width="125"
                    src={brand?.photo?.thumb_url || defaultUserImage}
                    alt={location?.name}
                  />
                  <img
                    src={ImgAvatarArrow}
                    alt="avatar"
                    className="absolute right-1 bottom-0"
                  />
                </div>

                <span className="block font-bold text-white text-xl mt-7 mb-4">
                  {location?.name}
                </span>

                {location?.address?.address && (
                  <div className="flex items-center mb-4">
                    <div className="text-center min-w-[28px]">
                      <i className="far fa-map-marker-alt col-span-1 text-xl text-white-400 text-center self-center" />
                    </div>
                    <span className="ml-1 col-span-7">
                      {location?.address?.address} {location?.address?.city}{" "}
                      {location?.address?.state}
                    </span>
                  </div>
                )}

                <div className="flex items-center mb-6">
                  <div className="text-center min-w-[28px]">
                    <i className="far fa-home col-span-1 text-xl text-white-400 text-center self-center" />
                  </div>
                  <button
                    className="ml-1 col-span-7 self-center underline"
                    onClick={() => {
                      if (location?.website) {
                        window.open(
                          location?.website.search("https://") === -1
                            ? `https://${location?.website}`
                            : location?.website
                        );
                      } else {
                        window.open(
                          brand?.website.search("https://") === -1
                            ? `https://${brand?.website}`
                            : brand?.website
                        );
                      }
                    }}
                  >
                    {location?.website || brand?.website}
                  </button>
                </div>

                <span className="block font-bold text-white text-xl mt-7 mb-4">
                  About {location?.name}:
                </span>

                <span className="block text-white-900">
                  {location?.description || brand?.description}
                </span>
              </div>

              <div className={"hidden lg:block mt-auto"}>
                <div className="flex justify-center mb-4">
                  <button
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                      )
                    }
                  >
                    <img
                      width={100}
                      src="/images/play_store_badge.png"
                      alt="Play Store"
                    />
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/heyhire/id1628579684"
                      )
                    }
                  >
                    <img
                      width={100}
                      className="ml-1"
                      src="/images/app_store_badge.png"
                      alt="App Store"
                    />
                  </button>
                </div>
                <div className="shadow-2xl text-center bg-white text-primary-500 py-4 flex justify-center">
                  <span className="font-semibold self-center mr-2">
                    Powered by{" "}
                  </span>
                  <img
                    height={10}
                    width={120}
                    alt="logo"
                    src="/logo/powered-by.svg"
                  />
                </div>
              </div>

              <Collapse
                className="public-profile-collapse lg:hidden"
                ghost
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <FiChevronUp size={24} color="white" />
                  ) : (
                    <FiChevronDown size={24} color="white" />
                  )
                }
              >
                <Panel
                  header={
                    <div className={"flex text-white"}>
                      <div
                        className={"rounded-full border-4 border-white h-min"}
                      >
                        <img
                          className={"rounded"}
                          src={brand?.photo?.thumb_url || defaultUserImage}
                          alt={location?.name}
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className={"ml-2"}>
                        <span className={"block text-lg font-bold"}>
                          {location?.name}
                        </span>
                        <button
                          className={"block underline cursor-pointer"}
                          onClick={() => {
                            if (location?.website) {
                              window.open(
                                location?.website.search("https://") === -1
                                  ? `https://${location?.website}`
                                  : location?.website
                              );
                            } else {
                              window.open(
                                brand?.website.search("https://") === -1
                                  ? `https://${brand?.website}`
                                  : brand?.website
                              );
                            }
                          }}
                        >
                          {location?.website || brand?.website}
                        </button>
                      </div>
                    </div>
                  }
                  key="1"
                >
                  <div>
                    <p className={"text-white ml-14"}>
                      {location?.address?.address}
                    </p>
                    <p className={"mt-4 text-white"}>
                      {location?.description || brand?.description}
                    </p>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div className="flex-1 lg:ml-16 lg:pt-8 p-4 pb-44 lg:pb-6">
              {!error ? (
                <div className="h-full">
                  <div className="flex bg-white shadow-lg  p-2 px-4 lg:p-6 rounded-md">
                    <p className="font-semibold">
                      ({positions?.length}) Open Position(s)
                    </p>
                  </div>

                  <div className="my-3 lg:my-4">
                    {positions?.map((item, i) => (
                      <Space
                        key={item.id}
                        direction="vertical"
                        size={8}
                        className="w-full"
                      >
                        <OpenPositionCard position={item} />
                      </Space>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex w-full h-full justify-center items-center">
                  <span className="text-slate-500 text-xl lg:text-6xl font-black tracking-widest decoration-slate-500 antialiased">
                    {error}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={"relative lg:hidden w-full bottom-0"}>
        <div
          className={
            "px-4 py-5 bg-primary-500 flex items-center justify-center shadow-[3px_0_0_2px_rgba(0,0,0,0.1)]"
          }
        >
          <span className={"text-white font-thin"}>
            Want to <strong className="font-bold">apply</strong> for this{" "}
            <strong className="font-bold">job?</strong>
          </span>
          <button
            onClick={() => {
              if (platform == "ios") {
                window.open(
                  "https://apps.apple.com/us/app/heyhire/id1628579684"
                );
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                );
              }
            }}
          >
            <img
              width={150}
              className="ml-4"
              src={
                platform === "ios"
                  ? "/images/app_store_badge.png"
                  : "/images/play_store_badge.png"
              }
              alt="App Store"
            />
          </button>
        </div>
        <div className="text-center bg-white-200 text-primary-500 py-4 flex justify-center">
          <span className="font-semibold self-center mr-2">Powered by </span>
          <img height={10} width={120} alt="logo" src="/logo/powered-by.svg" />
        </div>
      </div>
    </div>
  );
};

export default LocationProfile;
