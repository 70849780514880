import React, { useState } from "react";
import { Collapse } from "antd";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "./EmbeddedOpenPositionCard.scss";
import dayjs from "dayjs";
import { DateFormat } from "../../utils";

const MAX_LENGTH = 120;
const { Panel } = Collapse;

const EmbeddedOpenPositionCard = ({
  position,
  brandUrl = "",
  locationUrl = "",
}) => {
  const [openKeys, setOpenKeys] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [active, setActive] = useState(false);
  const mobileAppUrlCheck =
    process.env.REACT_APP_API_BASE_URL.search("stagingapi");
  const mobileAppUrl =
    mobileAppUrlCheck === -1 ? "heyhire.app://" : "staging.heyhire.app://";

  const openpage = () => {
    window.open(`https://heyhire.app/${brandUrl}/${locationUrl}`);

    // var isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod'),
    //     isAndroid = navigator.userAgent.match('Android');

    // // Mobile
    // if (isiOS || isAndroid) {
    //   if (isiOS) {
    //     setTimeout(function () { window.location = 'https://apps.apple.com/us/app/heyhire/id1628579684'; }, 50);
    //     window.location = `heyhire.app://?job_id=${position?.id}`;
    //   } else if (isAndroid) {
    //     setTimeout(function () { window.location = 'https://play.google.com/store/apps/details?id=com.heyhire&pli=1'; }, 100);
    //     window.location = `heyhire.app://?job_id=${position?.id}`;
    //   }
    // }else{
    //   window.open(`https://heyhire.app/${brandUrl}/${locationUrl}`);
    // }
  };

  const openStore = () => {
    var isiOS =
        navigator.userAgent.match("iPad") ||
        navigator.userAgent.match("iPhone") ||
        navigator.userAgent.match("iPod"),
      isAndroid = navigator.userAgent.match("Android");
    if (isAndroid) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
      );
    } else {
      window.open("https://apps.apple.com/us/app/heyhire/id1628579684");
    }
  };

  const renderToggler = () => (
    <div
      className={`flex flex-1 flex-row justify-between items-center md:h-10 lg:h-10 h-12 rounded-md md:bg-transparent lg:bg-transparent ${
        active ? "bg-primary-500" : "bg-gray-200"
      }`}
    >
      <span
        className={`flex flex-1 font-sans font-bold text-md pl-2 md:pl-0 lg:pl-0 lg:text-xl md:text-xl
       lg:text-black md:text-black lg:dark:text-white md:dark:text-white ${
         active ? "text-white pl-5" : "text-black"
       }`}
      >
        {position?.title}
      </span>
      <span className="flex-1 justify-end items-center hidden md:flex text-black dark:text-white lg:block ml-14">
        <i className="far fa-clock mr-2 text-gray-400 dark:text-white" />{" "}
        {position?.availability || "Part Time, Full Time"}
      </span>
      <div className="flex-1 justify-end font-light hidden text-black dark:text-white md:block lg:flex">
        <span
          className={
            " " +
            (openKeys &&
              openKeys.includes("1") &&
              " font-semibold text-white lg:text-gray-400")
          }
        >
          <i className="far fa-calendar mr-2" /> Start Date:{" "}
          {position?.start_date
            ? dayjs(position?.start_date).format(DateFormat)
            : "To be decided"}
        </span>
      </div>
      <div className="md:hidden lg:hidden absolute right-4 top-5 p-1">
        {active ? (
          <FiChevronUp size={20} color="white" />
        ) : (
          <FiChevronDown size={20} color="blue dark:neutral-600" />
        )}
      </div>
    </div>
  );

  const renderContent = () => (
    <div className="flex flex-col lg:flex-row md:flex-row items-center md:items-start lg:item-start justify-between">
      <div className="flex flex-col flex-1 md:hidden lg:hidden w-full pb-3">
        <span className="flex flex-1 font-sans font-bold text-sm text-black dark:text-white pr-5">
          {position?.title}
        </span>
        <div className="flex flex-1 text-[12px] md:text-lg font-light text-black dark:text-white">
          <span
            className={
              " " +
              (openKeys &&
                openKeys.includes("1") &&
                " font-semibold text-white lg:text-gray-400")
            }
          >
            <i className="far fa-calendar mr-2" /> Start Date:{" "}
            {position?.start_date
              ? dayjs(position?.start_date).format(DateFormat)
              : "To be decided"}
          </span>
        </div>
      </div>
      <p className="lg:block text-black dark:text-white text-sm mb-1 text-ellipsis">
        <div
          dangerouslySetInnerHTML={{
            __html: showDetails
              ? position?.description
              : `${position?.description?.substring(0, MAX_LENGTH)}`,
          }}
        />
        {/* 
        {showDetails
          ? position?.description
          : `${position?.description?.substring(0, MAX_LENGTH)}`} */}
      </p>
      {showDetails && (
        <div className="font-light lg:hidden md:hidden w-full">
          <div className="w-full text-gray-400 dark:text-white">
            <i className="far fa-clock mr-2" />{" "}
            {position?.availability || "Part Time, Full Time"}
          </div>
          <div className="w-full text-gray-400 dark:text-white">
            <i className="far fa-money-bill-wave mr-2" />{" "}
            {position?.wage ? position?.wage : "To be decided"}
          </div>
        </div>
      )}
      <div className="flex flex-col min-w-fit mt-4 lg:mt-0 md:mt-0">
        <div className="flex-row lg:flex-row md:flex-row hidden lg:block">
          <button
            className={
              "font-bold not-italic font-sans lg:ml-4 md:ml-4 py-1.5 px-3 rounded-md border-2 border-blue-400 dark:border-white hover:text-primary h-min " +
              (showDetails
                ? "bg-blue-400 text-white dark:text-neutral-600 dark:bg-white"
                : "text-blue-400 dark:text-white bg-white dark:bg-neutral-600")
            }
            onClick={() => setShowDetails(!showDetails)}
          >
            View Details
          </button>
          <button
            className="font-bold not-italic font-sans ml-4 py-2 px-3 rounded-md bg-primary-500 dark:bg-white text-white dark:text-primary-500 hover:bg-primary-400 h-min"
            onClick={() => openStore()}
          >
            Apply Now
          </button>
        </div>
        <div className="flex flex-row">
          <button
            className={
              "font-bold not-italic font-sans lg:hidden ml-4 py-1.5 px-3 rounded-md border-2 border-blue-400 dark:border-white hover:text-primary h-min " +
              (showDetails
                ? "bg-blue-400 dark:bg-white dark:text-neutral-600 text-white"
                : "text-blue-400 dark:text-white bg-white dark:bg-neutral-600")
            }
            onClick={() => setShowDetails(!showDetails)}
          >
            View Details
          </button>
          <button
            className="font-bold not-italic font-sans lg:hidden ml-4 py-2 px-3 rounded-md bg-primary-500 dark:bg-white dark:text-neutral-600 text-white hover:bg-primary-400 h-min"
            onClick={() => openpage()}
          >
            Apply Now
          </button>
        </div>
        {showDetails ? renderTimer() : null}
      </div>
    </div>
  );

  const renderTimer = () => (
    <div className="font-light hidden lg:block md:flex md:flex-row lg:flex-row mt-1">
      <span className="ml-14 dark:text-white">
        <i className="far fa-money-bill-wave mr-2 dark:text-white text-gray-400" />{" "}
        {position?.wage ? position?.wage : "To be decided"}
      </span>
    </div>
  );

  return (
    <div className="bg-gray-200 dark:bg-neutral-600 rounded-md md:my-1">
      <div className="hidden lg:block md:block py-4 px-6 ">
        {renderToggler()}
        {renderContent()}
      </div>
      <Collapse
        className="lg:hidden md:hidden bg-white dark:bg-neutral-600"
        ghost
        onChange={(isActive) => {
          setActive(isActive[0]);
        }}
      >
        <Panel header={renderToggler()} key="1" showArrow={false}>
          {renderContent()}
        </Panel>
      </Collapse>
    </div>
  );
};

export default EmbeddedOpenPositionCard;
