import React, { useEffect, useState } from "react";
import {
  defaultBrandImage,
  heyhireNewBlackLogoIcon,
  heyhireNewLogoIcon,
  leftArrowImg,
  profileRingIcon,
} from "../../assets/images";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getPlatform } from "../../utils";
import Analytics from "../../api/Analytics";
import Location from "../../api/Location";
import CardPositionMobileLoading from "../../utils/CardPositionMobileLoading";
import CardSinglePositionLoading from "../../utils/CardSinglePositionLoading";

const NewLocationProfile = () => {
  const passedProp = useLocation();
  const [brand, setBrand] = useState(passedProp?.state?.brand);
  const storedLocation = JSON.parse(localStorage.getItem("currentLocation"));
  const [error, setError] = useState(null);
  const [platform, setPlatform] = useState("ios");
  const navigate = useNavigate();
  const params = useParams();
  const [positions, setpositions] = useState([]);
  const [location, setLocation] = useState();
  const ogImageUrl = brand?.photo ? brand?.photo?.thumb_url : defaultBrandImage;
  const [distance, setDistance] = useState(null);
  const groupName = passedProp?.state && passedProp?.state?.group;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const _platform = getPlatform();
    setPlatform(_platform);
    analytics();
    loadPositions();
  }, []);

  const analytics = () => {
    const data = {
      brand_url_slug: params.businessName,
      location_url_slug: params.locationName,
      details: navigator.userAgent,
    };
    const capture = Analytics.trackAnalytics(data);
  };

  const loadPositions = async () => {
    setLoading(true);
    await Location.getBySlug(`${params.businessName}/${params.locationName}`)
      .then((response) => {
        document.title = `Job openings at ${response?.data?.data?.name}, Now Hiring! - Powered By HeyHire`;

        const _positions = response.data.data.positions;
        const filterJobs = _positions.filter((i) => i.archived_at === null);
        setpositions(filterJobs);
        if (response.data.data?.brand) {
          setBrand(response.data.data?.brand);
        }
        setLocation(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (storedLocation && storedLocation?.city && storedLocation?.state) {
      if (location)
        handleCalculateDistance(
          storedLocation?.latitude,
          storedLocation?.longitude,
          location?.address?.lat,
          location?.address?.lng
        );
    }
  }, [storedLocation]);

  const handleCalculateDistance = (lat1, long1, lat2, long2) => {
    const R = 3958.8; // Earth radius in miles
    const numLat1 = parseFloat(lat1);
    const numLong1 = parseFloat(long1);
    const numLat2 = parseFloat(lat2);
    const numLong2 = parseFloat(long2);

    if (
      isNaN(numLat1) ||
      isNaN(numLong1) ||
      isNaN(numLat2) ||
      isNaN(numLong2)
    ) {
      console.error("Invalid coordinates provided");
      return;
    }

    const dLat = toRadians(numLat2 - numLat1);
    const dLon = toRadians(numLong2 - numLong1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(numLat1)) *
        Math.cos(toRadians(numLat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    setDistance(distance.toFixed(2));
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="w-full fixed z-10 bg-white flex-shrink-0">
        <div className="h-16 md:h-20 top-0 w-full bg-gray-900 flex md:rounded-b-2xl z-[1]">
          <div className="m-4 px-4 md:px-0 mx-auto my-auto w-full">
            <div className="flex justify-between w-full md:w-[75%] xl:w-[80%] ml-auto md:px-6 xl:px-24 2xl:px-36">
              <div className="flex flex-col justify-center md:items-center cursor-pointer">
                <img
                  className="w-28 md:w-36"
                  src={heyhireNewLogoIcon}
                  alt="heyhire"
                  onClick={() => {
                    navigate("/jobs");
                  }}
                />
                <span className="font-bold text-[8px] w-28 md:w-36 flex justify-end text-white">
                  {storedLocation?.city}, {storedLocation?.state}
                </span>
              </div>

              <div className="my-auto">
                <div
                  className="cursor-pointer px-6 flex items-center justify-center bg-white text-black hover:bg-gray-900 hover:text-white hover:border-white border transition-all text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-2"
                  onClick={() =>
                    window.open("https://www.heyhire.com/", "_blank")
                  }
                >
                  Post a Job
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-20 md:flex hidden h-screen">
        <div className="w-[25%] xl:w-[20%] bg-white p-4 border-r border-warmgray-300 flex flex-col rounded-br-2xl">
          <div className="relative flex justify-center bg-white rounded-full p-1 w-36 h-36 mx-auto my-4">
            <img
              className="rounded-full object-contain"
              src={brand?.photo ? brand?.photo?.thumb_url : defaultBrandImage}
              alt="heyhire"
            />
            <img
              src={profileRingIcon}
              alt="avatar"
              className="absolute inset-0"
            />
          </div>
          <div className="text-center">
            <p className="font-extrabold text-[20px]">{brand?.name}</p>
            <div className="flex flex-col my-6 gap-1.5">
              <p className="font-arial font-semibold text-[15px]">
                <span>
                  <i className="fas fa-map-marker-alt text-black text-[15px] my-auto" />
                </span>{" "}
                {location?.address?.address}
              </p>
              <p className="font-arial font-semibold text-[15px]">
                {distance && `${distance} mi away`}
              </p>
              <p
                className="font-arial font-semibold text-[15px] cursor-pointer"
                onClick={() =>
                  window.open(
                    (brand?.website.startsWith("http") ? "" : "https://") +
                      brand?.website,
                    "_blank",
                    "noopener noreferrer"
                  )
                }
              >
                {brand?.website}
              </p>
            </div>
            <hr className="w-full border border-warmgray-200 hidden md:block" />
          </div>
          <div>
            <p className="font-extrabold my-4">About {brand?.name}:</p>
            <p className="text-[14px] font-arial">{brand?.description}</p>
          </div>
          <div className="mt-auto mb-4">
            <div className="flex justify-center mb-6">
              <button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                  )
                }
              >
                <img
                  width={130}
                  src="/images/play_store_badge.png"
                  alt="Play Store"
                />
              </button>
              <button
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/heyhire/id1628579684"
                  )
                }
              >
                <img
                  width={120}
                  className="ml-2"
                  src="/images/app_store_badge.png"
                  alt="App Store"
                />
              </button>
            </div>
            <div className="py-4 flex justify-center">
              <img
                height={180}
                width={180}
                alt="logo"
                src={heyhireNewBlackLogoIcon}
              />
            </div>
          </div>
        </div>

        <div className="w-[75%] xl:w-[80%] bg-white overflow-y-auto md:px-6 xl:px-24 2xl:px-36">
          <div className="bg-white rounded-br-3xl py-6 xl:py-10 text-center border border-warmgray-200 shadow-lg">
            <p className="font-extrabold text-xl md:text-2xl xl:text-3xl">
              <span className="text-gray-900">Join our team! </span>
              <span className="text-[#BC74A9]">Apply in just a few taps!</span>
            </p>
          </div>

          <div className="my-6">
            <div
              className="flex cursor-pointer"
              onClick={() =>
                groupName ? navigate(`/group/${groupName}`) : navigate("/jobs")
              }
            >
              <img src={leftArrowImg} className="w-4 h-4 my-auto mr-2" />
              <span className="text-[15px] font-normal">
                {groupName ? "Groups" : "Job search"}
              </span>
            </div>

            <div className="mt-10">
              <p className="font-extrabold text-[20px]">
                Currently Hiring For:
              </p>
              <p className="mt-2 text-[14px] font-arial">
                Tap position to view information & apply!
              </p>
            </div>

            <div className="bg-[#f6f6f6] rounded-xl p-4 mt-8">
              {loading ? (
                <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 gap-6">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <CardSinglePositionLoading key={index} />
                  ))}
                </div>
              ) : (
                <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 gap-6">
                  {positions?.filter(
                    (position) => position?.archived_at === null
                  ).length > 0 ? (
                    positions
                      ?.filter((position) => position?.archived_at === null)
                      .map((position) => (
                        <div
                          className="rounded-2xl flex flex-col items-stretch text-center cursor-pointer shadow-xl"
                          onClick={() => {
                            const encodedTitle = encodeURIComponent(
                              position?.title
                            );
                            navigate(
                              `/${params.businessName}/${params.locationName}/${encodedTitle}`,
                              {
                                state: {
                                  position,
                                  location,
                                  multipleLocation: false,
                                },
                              }
                            );
                          }}
                        >
                          <div className="border-b border-warmgray-400 h-40">
                            <img
                              src={
                                brand?.photo
                                  ? brand?.photo?.thumb_url
                                  : defaultBrandImage
                              }
                              alt="brand_logo"
                              className={`w-full h-full rounded-t-2xl bg-white`}
                            />
                          </div>
                          <div className="my-auto flex flex-col justify-center items-center py-1 bg-white rounded-b-2xl">
                            <div className="font-bold text-[16px]">
                              {position?.title}
                            </div>
                            <div className="text-[12px]">
                              {position?.hiring_status === 1
                                ? "Immediately Hiring"
                                : position?.hiring_status === 2
                                ? "Externally Hiring"
                                : "Accepting Applications"}
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="text-[14px] flex items-center justify-center p-4 my-4">
                      No Position Available
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="h-screen pt-16 flex md:hidden flex-col bg-white">
        <div className="bg-white border-b border-warmgray-200 rounded-b-2xl shadow-md">
          <div className="py-6 text-center border border-warmgray-200 rounded-b-2xl shadow-md">
            <p>
              <span className="text-lg text-gray-900 font-extrabold">
                Join our team!{" "}
              </span>
              <span className="text-lg text-[#BC74A9] font-extrabold">
                Apply in just a few taps!
              </span>
            </p>
          </div>

          <div
            className="flex cursor-pointer px-4 mt-2"
            onClick={() =>
              groupName ? navigate(`/group/${groupName}`) : navigate("/jobs")
            }
          >
            <img src={leftArrowImg} className="w-3 h-3 my-auto mr-2" />
            <span className="text-[12px] font-normal">
              {groupName ? "Groups" : "Job search"}
            </span>
          </div>

          <div className="relative flex justify-center bg-white rounded-full p-1 w-32 h-32 mx-auto my-4">
            <img
              className="rounded-full object-contain"
              src={brand?.photo ? brand?.photo?.thumb_url : defaultBrandImage}
              alt="heyhire"
            />
            <img
              src={profileRingIcon}
              alt="avatar"
              className="absolute inset-0"
            />
          </div>

          <div className="px-4 text-center">
            <p className="font-extrabold text-[16px]">{brand?.name}</p>
            <div className="flex flex-col mb-4 mt-2">
              <p className="font-arial font-bold text-[10px]">
                <span>
                  <i className="fas fa-map-marker-alt text-black text-[10px] my-auto" />
                </span>{" "}
                {location?.address?.address}
              </p>
              <p className="font-arial font-bold text-[10px]">
                {distance && `${distance} mi away`}
              </p>
              <p
                className="font-arial font-bold text-[10px] cursor-pointer"
                onClick={() =>
                  window.open(brand?.website, "_blank", "noopener noreferrer")
                }
              >
                {brand?.website}
              </p>
            </div>
            <hr className="w-full border border-warmgray-200 hidden md:block" />
          </div>

          <div className="px-4 pb-4 border-t border-warmgray-300">
            <p className="font-bold mb-2 mt-4 text-[14px]">
              About {brand?.name}:
            </p>
            <p className="text-[12px] font-arial">{brand?.description}</p>
          </div>
        </div>

        <div className="my-6 px-4 bg-white">
          <p className="font-extrabold text-[14px]">Currently Hiring For:</p>
          <p className="text-[12px] font-arial">
            Tap position to view information & apply!
          </p>

          <div className="bg-[#f6f6f6] rounded-xl">
            {loading ? (
              <div className="flex flex-col gap-2 p-2.5 my-4">
                {Array.from({ length: 4 }).map((_, index) => (
                  <CardPositionMobileLoading key={index} />
                ))}
              </div>
            ) : (
              <div className="flex flex-col gap-2 my-4 p-2.5">
                {positions?.filter((position) => position?.archived_at === null)
                  .length > 0 ? (
                  positions
                    ?.filter((position) => position?.archived_at === null)
                    .map((position) => (
                      <div
                        key={position?.id} // Assuming position has a unique id
                        className="bg-white rounded-lg flex p-2 gap-2 cursor-pointer shadow-md border border-warmgray-200"
                        onClick={() => {
                          const encodedTitle = encodeURIComponent(
                            position?.title
                          );
                          navigate(
                            `/${params.businessName}/${params.locationName}/${encodedTitle}`,
                            {
                              state: {
                                position,
                                location,
                                multipleLocation: false,
                              },
                            }
                          );
                        }}
                      >
                        <div>
                          <img
                            src={
                              brand?.photo
                                ? brand?.photo?.thumb_url
                                : defaultBrandImage
                            }
                            alt=""
                            className="w-10 h-10 rounded-full"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-bold text-[14px]">
                            {position?.title}
                          </p>
                          <p className="text-[10px]">
                            {position?.hiring_status === 1
                              ? "Immediately Hiring"
                              : position?.hiring_status === 2
                              ? "Externally Hiring"
                              : "Accepting Applications"}
                          </p>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="text-[14px] flex items-center justify-center p-4 my-4">
                    No Position Available
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="bg-white justify-center text-center p-2 mt-auto flex border-t border-warmgray-200">
          <p className="my-auto mr-2 text-sm">
            Want to <span className="font-bold">apply</span> to this{" "}
            <span className="font-bold">business</span>?
          </p>
          <button
            onClick={() => {
              if (platform == "ios") {
                window.open(
                  "https://apps.apple.com/us/app/heyhire/id1628579684"
                );
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                );
              }
            }}
          >
            <img
              width={80}
              className="ml-4"
              src={
                platform === "ios"
                  ? "/images/app_store_badge.png"
                  : "/images/play_store_badge.png"
              }
              alt="App Store"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLocationProfile;
