import React from 'react'

const Banner = () => {
  return (
    <div className="h-32 lg:h-32 md:h-40 bg-white-400 lg:pb-40 lg:rounded-b-3xl bg-no-repeat bg-cover  bg-gradient-to-r  from-primary-400 to-primary-500">
      <div className="w-full h-full mx-auto p-4">
        <h1 className="media-text text-xl sm:text-3xl md:text-3xl lg:text-5xl text-center pt-[40px]">
          <span className="banner-text text-shadow-orange text-white">
            {" "}
            Join our team!
          </span>
          <span className="banner-text text-shadow-orange text-yellow-500">
            {" "}
            Apply in just a few taps!
          </span>
        </h1>
        <div className="h-full flex justify-end items-center lg:items-end pb-20 lg:pt-32">
          {/* <button className="hidden lg:block px-4 py-2 lg:mr-10 rounded-md border border-primary-500 bg-white hover:bg-primary-500 text-primary-500 hover:text-white cursor-pointer">
                Share <i className="fas fa-share-all ml-1" />
              </button> */}
        </div>
      </div>
    </div>
  );
}

export default Banner