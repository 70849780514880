export const DateFormat = "MM/DD/YYYY";
export const DateFormatWithTime = "MM/DD/YYYY - hh:mm A";

export const getPlatform = () => {
  const isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod');
  const isAndroid = navigator.userAgent.match('Android');
  if (isAndroid) {
    return "android"
    // setPlatform("android");
  } else {
    return "ios"
    // setPlatform("ios");
  }
}
