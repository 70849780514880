import React from "react";
import { defaultBrandImage } from "../assets/images";

const CardBusinessLoading = () => {
  return (
    <div className="shadow-lg rounded-xl p-4 bg-white">
      <div className="animate-pulse flex space-x-4">
        <div className="flex py-1 w-full gap-4">
          <div className="flex flex-col flex-1 mb-4">
            <div className="h-2 bg-slate-200 rounded mb-6 w-3/5"></div>
            <div className="space-y-3 w-3/4">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
          <div class="rounded-full bg-slate-200 h-12 w-12"></div>
        </div>
      </div>
    </div>
  );
};

export default CardBusinessLoading;
