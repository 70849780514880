import axios from "./api";

const Brand = {
  getBrand(brand) {
    return axios.get(`/public-profile/brand/${brand}`);
  },
  getAllBrandForPublicPage(body) {
    return axios.post(`public-profile/heyhire`, body);
  },
  getGroupDetails(groupUrl) {
    return axios.get(`/public-profile/group/${groupUrl}`);
  },
  getBrandById(id) {
    return axios.get(`/public-profile/group/brand/${id}`)
  },
  getAllBusinesses(){
    return axios.get(`public-profile/heyhire/locations`)
  },
  getAllPositions(){
    return axios.get(`public-profile/heyhire/positions`)
  },
}

export default Brand;
