import React, { useState } from "react";
import { Collapse } from "antd";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "./OpenPositionCard.scss";
import dayjs from "dayjs";
import { DateFormat } from "../../utils";

const MAX_LENGTH = 120;
const { Panel } = Collapse;

const OpenPositionCard = ({ position }) => {
  const [openKeys, setOpenKeys] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const mobileAppUrlCheck = process.env.REACT_APP_API_BASE_URL.search("stagingapi");
  const mobileAppUrl = mobileAppUrlCheck === -1 ? 'heyhire.app://' : 'staging.heyhire.app://';

  const openpage = () => {
    var isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod'),
        isAndroid = navigator.userAgent.match('Android');
    console.log('isiOS', isiOS);
    console.log('isAndroid', isAndroid);

    // Mobile
    if (isiOS || isAndroid) {
      if (isiOS) {
        setTimeout(function () { window.location = 'https://apps.apple.com/us/app/heyhire/id1628579684'; }, 50);
        window.location = `heyhire.app://?job_id=${position?.id}`;
      } else if (isAndroid) {
        setTimeout(function () { window.location = 'https://play.google.com/store/apps/details?id=com.heyhire&pli=1'; }, 100);
        window.location = `heyhire.app://?job_id=${position?.id}`;
      }
    }
  };

  const openStore = () => {
    var isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod'),
        isAndroid = navigator.userAgent.match('Android');
        console.log('isiOS', isiOS);
    if (isAndroid) {
      window.open("https://play.google.com/store/apps/details?id=com.heyhire&pli=1")
    } else {
      window.open("https://apps.apple.com/us/app/heyhire/id1628579684")
    }
  };

  const renderToggler = () => (
    <div className={`flex flex-row justify-between`}>
      <div className={ "font-bold text-xl " + (openKeys && openKeys.includes("1") && "text-white lg:text-black")}>
        {position?.title}
      </div>
      <div className="font-light hidden md:block lg:block">
        <span className={" " + (openKeys && openKeys.includes("1") && " font-semibold text-white lg:text-gray-400")}>
          <i className="far fa-calendar mr-2" /> Start Date: {position?.start_date ? dayjs(position?.start_date).format(DateFormat) : "To be decided"}
        </span>
      </div>
    </div>
  );

  const renderContent = () => (
    <div className="flex flex-col md:flex-row lg:flex-row justify-between">
      <div className="flex font-light md:hidden lg:hidden justify-end mb-2">
        <span
          className={
            " " +
            (openKeys &&
              openKeys.includes("1") &&
              " font-semibold text-white lg:text-gray-400")
          }
        >
          <i className="far fa-calendar mr-2" /> Start Date:{" "}
          {position?.start_date
            ? dayjs(position?.start_date).format(DateFormat)
            : "To be decided"}
        </span>
      </div>
      <div className={"flex flex-col lg:flex-row items-center justify-between"}>
        <p className="lg:block text-black text-sm mb-1 text-ellipsis">
          <div
            dangerouslySetInnerHTML={{
              __html: showDetails
                ? position?.description
                : `${position?.description?.substring(0, MAX_LENGTH)}`,
            }}
          />
          {/* {showDetails
            ? position?.description
            : `${position?.description?.substring(0, MAX_LENGTH)}`} */}
        </p>
        {showDetails && (
          <div className="font-light lg:hidden w-full ml-56">
            <div className="w-full">
              <i className="far fa-clock mr-2 text-gray-400" />{" "}
              {position?.availability || "Part Time, Full Time"}
            </div>
            <div className="w-full">
              <i className="far fa-money-bill-wave mr-2 text-gray-400" />{" "}
              {position?.wage === " " ? "To be decided" : position?.wage}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center min-w-fit mt-4 lg:mt-0">
        <div className="flex flex-row">
          <button
            className={
              "font-black lg:ml-4 py-1.5 px-3 rounded border-2 box-border border-orange-400 hover:bg-orange-400 hover:text-primary h-min " +
              (showDetails
                ? "bg-orange-400 text-primary-500"
                : "text-primary-400 bg-white")
            }
            onClick={() => setShowDetails(!showDetails)}
          >
            View Details
          </button>
          <button
            className="md:hidden lg:hidden font-black ml-4 py-2 px-3 rounded bg-primary-500 text-white hover:bg-primary-400 h-min"
            onClick={() => {
              // window.open(`${mobileAppUrl}?job_id=${position?.id}`);
              openpage();
            }}
          >
            Apply Now
          </button>
          <button
            className="hidden md:block font-black ml-4 py-2 px-3 rounded bg-primary-500 text-white hover:bg-primary-400 h-min"
            onClick={() => {
              openStore();
              // window.open("https://apps.apple.com");
            }}
          >
            Apply Now
          </button>
        </div>
        {showDetails ? renderTimer() : null}
      </div>
    </div>
  );

  const renderTimer = () => (
    <div className="flex font-light hidden lg:block lg:flex-row mt-1">
      <span className={"ml-14"}>
        <i className="far fa-clock mr-2 text-gray-400" /> { position?.availability || "Part Time, Full Time"}
      </span>
      <br />
      <span className={"ml-14"}>
        <i className="far fa-money-bill-wave mr-2 text-gray-400" /> {position?.wage === " " ? 'To be decided' : position?.wage}
      </span>
    </div>
  );

  return (
    <div className="bg-white shadow-lg rounded-md md:my-1">
      <div className="hidden lg:block py-4 px-6">
        {renderToggler()}
        {renderContent()}
        {/* {renderTimer()} */}
      </div>
      <Collapse
        className="open-position-card-accordion lg:hidden bg-white"
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? (
            <FiChevronUp size={24} color="#594A9E" />
          ) : (
            <FiChevronDown size={24} color="#594A9E" />
          )
        }
        // onChange={setOpenKeys}
      >
        <Panel header={renderToggler()} key="1">
          {renderContent()}
        </Panel>
      </Collapse>
    </div>
  );
};

export default OpenPositionCard;
