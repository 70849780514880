import locationImg from "./images/location.svg";
import PwWhite from "./images/powered-by-white.svg";
import DSposter from "./images/design-poster.png";
import QRS from "./images/qrcodesample.png";
import userImg from "./images/defaultUser.png";
import staticImg from "./images/loboshospitality_360.jpeg";
import toasty from "./images/toasty.png";
import leftArrow from "./images/leftArrow.svg";
import profileRing from "./images/profileRing.svg";
import certifications from "./images/certifications.svg";
import stockImage from "./images/default-placeholder-image.png";
import selectedIcon from "./images/selectedIcon.svg";
import heyhireNewLogo from "./images/heyhireNewLogo.svg";
import heyhireNewBlackLogo from "./images/heyhireNewBlackLogo.svg";
import searchLogo from "./images/new-search-logo.svg";

export const LocationImg = locationImg;
export const PoweredByWhite = PwWhite;
export const DesignPoster = DSposter;
export const QRcodeSample = QRS;
export const defaultUserImage = userImg;
export const staticBrandImage = staticImg;
export const toastyImage = toasty;
export const leftArrowImg = leftArrow;
export const profileRingIcon = profileRing;
export const certificationsIcon = certifications;
export const defaultBrandImage = stockImage;
export const SelectedIcon = selectedIcon;
export const heyhireNewLogoIcon = heyhireNewLogo;
export const heyhireNewBlackLogoIcon = heyhireNewBlackLogo;
export const searchNewLogo = searchLogo;
